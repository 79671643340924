type LoadScriptParams = {
  id: string;
  src: string;
};

const scripts: { [id: string]: ScriptLoader } = {};

type Resolver = () => void;

class ScriptLoader {
  script?: HTMLScriptElement;

  loaded = false;

  resolvers: Resolver[] = [];

  constructor(src: string) {
    this.script = document.createElement('script');
    this.script.src = src;
    this.script.addEventListener('load', () => this.onLoad());
    document.head.appendChild(this.script);
  }

  onLoad() {
    this.loaded = true;
    this.resolvers.forEach((resolve) => resolve());
  }

  addResolver(resolve: Resolver) {
    if (this.loaded) {
      resolve();
    } else {
      this.resolvers.push(resolve);
    }
  }
}

export default ({ id, src }: LoadScriptParams): Promise<void> =>
  new Promise((resolve) => {
    if (!(id in scripts)) {
      scripts[id] = new ScriptLoader(src);
    }
    scripts[id].addResolver(resolve);
  });
