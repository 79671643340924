import { ActionContext } from 'vuex';
import { RootState, CabinetState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  mutation notifyCompanyUser ($companyId: ID!, $loginOrPhone: ID!) {
    notifyUser (companyId: $companyId, id: $loginOrPhone)
  }
`;

export default (
  {
    rootState: {
      cabinet: { company },
      auth: { token },
    },
  }: ActionContext<CabinetState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginOrPhone: string,
) =>
  api.graphql({
    query,
    variables: {
      companyId: company?.id,
      loginOrPhone,
    },
    token,
  });
