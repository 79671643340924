import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ab26fd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-menu" }
const _hoisted_2 = { class: "v-app-drawer--logo d-flex flex-center" }
const _hoisted_3 = {
  key: 0,
  class: "side-nav"
}
const _hoisted_4 = { class: "side-nav--items" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FuelupLogo = _resolveComponent("FuelupLogo")!
  const _component_VSpacer = _resolveComponent("VSpacer")!
  const _component_IconCloseOutlined = _resolveComponent("IconCloseOutlined")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_v_menu_item = _resolveComponent("v-menu-item")!
  const _component_v_spacer = _resolveComponent("v-spacer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FuelupLogo, { class: "logo" }),
      _createVNode(_component_VSpacer),
      (_ctx.isButtonCloseVisible)
        ? (_openBlock(), _createBlock(_component_UiButton, {
            key: 0,
            class: "button-close",
            type: "text",
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconCloseOutlined)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.company)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("nav", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
              return (_openBlock(), _createBlock(_component_v_menu_item, {
                key: item.label,
                icon: item.icon,
                to: item.to,
                modelValue: item.expanded
              }, _createSlots({
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: "side-nav-item-label",
                    onClick: ($event: any) => (_ctx.handleMenuItemClick(item.items))
                  }, _toDisplayString(item.label), 9, _hoisted_5)
                ]),
                _: 2
              }, [
                (item.items)
                  ? {
                      name: "items",
                      fn: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (child) => {
                          return (_openBlock(), _createBlock(_component_v_menu_item, {
                            key: child.label,
                            to: child.to,
                            onClick: _ctx.close
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(child.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["to", "onClick"]))
                        }), 128))
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["icon", "to", "modelValue"]))
            }), 128))
          ]),
          _createVNode(_component_v_spacer)
        ]))
      : _createCommentVNode("", true)
  ]))
}