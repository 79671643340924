import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AList = _resolveComponent("AList")!

  return (_openBlock(), _createBlock(_component_AList, {
    size: _ctx.size,
    bordered: _ctx.bordered,
    dataSource: _ctx.dataSource
  }, {
    renderItem: _withCtx(({ item }) => [
      _renderSlot(_ctx.$slots, "renderItem", _normalizeProps(_guardReactiveProps({ item })))
    ]),
    _: 3
  }, 8, ["size", "bordered", "dataSource"]))
}