import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ATag = _resolveComponent("ATag")!
  const _component_UiText = _resolveComponent("UiText")!
  const _component_ATreeSelect = _resolveComponent("ATreeSelect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'ant-form-item-has-error': _ctx.error }, "input-wrapper"])
  }, [
    _createVNode(_component_ATreeSelect, {
      value: _ctx.valueProxy,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valueProxy) = $event)),
      open: _ctx.isOpened,
      treeData: _ctx.items,
      treeCheckable: _ctx.treeCheckable,
      treeNodeFilterProp: _ctx.treeNodeFilterProp,
      multiple: _ctx.treeCheckable,
      showCheckedStrategy: _ctx.showCheckedStrategy,
      allowClear: _ctx.allowClear,
      showSearch: _ctx.showSearch,
      disabled: _ctx.disabled,
      treeCheckStrictly: _ctx.treeCheckStrictly,
      popupClassName: _ctx.popupClassName,
      treeDefaultExpandAll: _ctx.treeDefaultExpandAll,
      class: "v-select-tree",
      placeholder: _ctx.placeholder,
      treeDataSimpleMode: "",
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpened = false)),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOpened = true))
    }, {
      tagRender: _withCtx(({ label, value }) => [
        _createVNode(_component_ATag, {
          closable: _ctx.tagRemoveIconVisible,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpened = true)),
          onClose: ($event: any) => (_ctx.removeItem(value))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(label), 1)
          ]),
          _: 2
        }, 1032, ["closable", "onClose"])
      ]),
      notFoundContent: _withCtx(() => [
        _createVNode(_component_UiText, {
          type: "secondary",
          class: "ml-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Нет данных")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "open", "treeData", "treeCheckable", "treeNodeFilterProp", "multiple", "showCheckedStrategy", "allowClear", "showSearch", "disabled", "treeCheckStrictly", "popupClassName", "treeDefaultExpandAll", "placeholder"]),
    (_ctx.error && _ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_UiText, {
          key: 0,
          class: "input-error-message",
          type: "danger"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}