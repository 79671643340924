import loadScript from '@/plugins/load-script';

// noinspection SpellCheckingInspection
const KEY = process.env.VUE_APP_RECAPTCHA;
const SCRIPT_ID = '_recaptcha_';

/**
 * Загрузка скрипта recaptcha
 */
export const loadRecaptcha = (): Promise<void> =>
  loadScript({
    id: SCRIPT_ID,
    src: 'https://www.google.com/recaptcha/api.js?render=' + KEY,
  });

/**
 * Устанавливает видимость бейджа recaptcha
 */
export const setBadgeVisible = (visible: boolean) => {
  if (document !== undefined) {
    document.documentElement.classList.toggle('auth', visible);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const grecaptcha: any;

/**
 * Получение токена Google ReCaptcha v3
 */
export default (action: string): Promise<string> =>
  new Promise((resolve) => {
    loadRecaptcha().then(() => {
      grecaptcha.ready(() => grecaptcha.execute(KEY, { action }).then(resolve));
    });
  });
