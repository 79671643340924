import { defineComponent, h, VNode, withDirectives } from 'vue';
import './VBtn.scss';
import { RouterLink } from 'vue-router';
import ripple from '@/plugins/ripple';

export default defineComponent({
  name: 'VBtn',
  directives: {
    // ripple,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    ripple: { type: Boolean, default: true },
    to: [String, Object],
    href: String,
    replace: Boolean, // ???
    // размеры
    large: Boolean,
    small: Boolean,
    // стили
    flat: Boolean,
    tile: Boolean,
    icon: Boolean,
    rounded: Boolean,
    outlined: Boolean,
    color: { type: String, default: 'default' },
    disabled: Boolean,
  },

  render(): VNode {
    const {
      type,
      to,
      href,
      large,
      small,
      flat,
      tile,
      icon,
      rounded,
      outlined,
      color,
      ripple: rippleEnabled,
      disabled,
    } = this;

    const renderControl = (): VNode =>
      h(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-nested-ternary
        to ? (RouterLink as any) : href ? 'a' : 'button',
        {
          to,
          replace: this.replace || undefined,
          href,
          type: to ? undefined : type,
          disabled,
          class: {
            'v-btn': true,
            // 'v-btn--filled': !outlined,
            'v-btn--outlined': outlined && !flat,
            'v-btn--flat': flat && !outlined,
            'v-size--default': !(large || small),
            'v-size--large': large && !small,
            'v-size--small': small && !large,
            'v-btn--tile': tile || icon,
            rounded: !(icon || rounded),
            'rounded-pill': icon || rounded,
            [color]: !(outlined || flat),
            [`${color}--text`]: outlined || flat,
            /*
             * ['dark']: dark && !(outlined || flat)
             * 'default': !(primary || secondary || outlined || flat),
             * 'default--text': !(primary || secondary) && (outlined || flat),
             * 'primary': primary && !(outlined || secondary || flat || color),
             * 'primary--text': primary && (outlined || secondary || flat),
             * 'secondary': secondary && !(outlined || primary || flat),
             * 'secondary--text': secondary && (outlined || flat) && !primary
             */
          },
          /*
           * directives: disabled || !rippleEnabled ? [] : [{ name: 'ripple' }],
           * onClick: (e: Event) => this.$emit('click', e),
           */
        },
        to ? () => this.$slots.default?.() : this.$slots.default?.(),
      );

    return disabled || !rippleEnabled ? renderControl() : withDirectives(renderControl(), [[ripple]]);
  },
});
