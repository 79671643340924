
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SberId',
  methods: {
    connect() {
      const utm = String(this.$route.query['source'] || this.$route.query['utm_source'] || '') || null;
      document.location.href = '/api/sbidConnect' + (utm ? `?utm_source=${utm}` : '');
    },
  },
});
