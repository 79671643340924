
import { defineComponent, ref, watch, PropType } from 'vue';
import ADatePicker from 'ant-design-vue/es/date-picker';
import localeRu from 'ant-design-vue/es/date-picker/locale/ru_RU';
import dayjs, { Dayjs } from 'dayjs';

localeRu.lang.locale = 'ru';

export default defineComponent({
  components: {
    ARangePicker: ADatePicker.RangePicker,
  },
  props: {
    /**
     * Выбранный диапазон дат
     */
    modelValue: {
      type: Array as PropType<string[] | Date[]>,
      default: () => [],
    },
    /**
     * Отключить элемент
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Заполнитель
     */
    placeholder: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
    /**
     * Формат даты
     */
    format: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    /**
     * Режим "только чтение"
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * Разрешить очищать ввод
     */
    allowClear: {
      type: Boolean,
      default: false,
    },
    /**
     * Показывать часы и минуты
     */
    showTime: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const defaultRange = ref();

    watch(
      () => props.modelValue,
      (modelValue) => {
        defaultRange.value = modelValue.map((i) => dayjs(i));
      },
      { deep: true, immediate: true },
    );

    return {
      defaultRange,
      localeRu,
      update(dates: [string, string] | [Dayjs, Dayjs]) {
        emit(
          'update:modelValue',
          dates.map((date) => (date as Dayjs).toDate()),
        );
      },
    };
  },
});
