import { defineComponent, h, Transition, VNode } from 'vue';
import './VDialog.scss';
import { VOverlay } from '@/components/Layout';

let visibleDialogs = 0;
let bodyFixed = false;

export default defineComponent({
  props: {
    width: [Number, String],
    modelValue: [Boolean, Number, String],
    modal: Boolean,
    activator: Object,
  },
  data() {
    return {
      isMounted: false,
      timeout: 0 as ReturnType<typeof setTimeout> | number,
    };
  },
  mounted() {
    this.isMounted = !!this.modelValue;
    this.checkVisible();
  },
  unmounted() {
    this.isMounted = false;
  },
  watch: {
    modelValue(val) {
      if (val) {
        this.isMounted = true;
        visibleDialogs++;
        setTimeout(() => {
          (this.$refs.dialog as HTMLDivElement)?.style?.removeProperty('--from-x');
          (this.$refs.dialog as HTMLDivElement)?.style?.removeProperty('--from-y');
        }, 200);
      } else {
        visibleDialogs--;
      }
      this.checkVisible();
    },
    isMounted(val: boolean) {
      window.document.body[val ? 'appendChild' : 'removeChild'](this.$el);
    },
  },

  render(): VNode | null {
    const rect: DOMRect = (this.activator?.$el || this.activator)?.getBoundingClientRect();
    const offsetX = rect ? (rect.left || rect.x) + rect.width / 2 - window.innerWidth / 2 : 0;
    const offsetY = rect ? (rect.top || rect.y) + rect.height / 2 - window.innerHeight / 2 : 0;
    // console.log({ modelValue: this.modelValue, isMounted: this.isMounted });
    return h(
      Transition,
      { name: 'dialog' },
      this.modelValue && this.isMounted
        ? () => [
            h('div', { class: 'v-dialog--wrapper' }, [
              h(VOverlay, { class: 'v-dialog--overlay', onClick: this.overlayClick }),
              h(
                'div',
                {
                  ref: 'dialog',
                  class: 'v-dialog',
                  style: {
                    width: `${this.width}px`,
                    '--from-x': offsetX ? `${offsetX}px` : undefined,
                    '--from-y': offsetY ? `${offsetY}px` : undefined,
                  },
                  role: 'dialog',
                },
                this.$slots.default?.(),
              ),
            ]),
          ]
        : () => [],
    );
  },
  methods: {
    overlayClick() {
      if (this.modal) {
        clearTimeout(this.timeout);
        const classList = (this.$refs.dialog as HTMLDivElement)?.classList;
        classList?.add('v-dialog--shake');
        this.timeout = setTimeout(() => classList?.remove('v-dialog--shake'), 200);
      } else {
        this.$emit('update:modelValue', false);
      }
    },
    checkVisible() {
      // document.documentElement.classList.toggle('fixed', this.value)
      const newBodyFixed = visibleDialogs > 0;
      if (newBodyFixed !== bodyFixed) {
        document.documentElement.classList.toggle('fixed', newBodyFixed);
        bodyFixed = newBodyFixed;
      }
    },
  },
});
