import { api } from '@/store';
import { q } from '@/store/api';
import { getAuthToken } from '@/store/auth';
import useQuery from '@/helpers/useQuery';
import { graphql } from '@/gql/gql';
import {
  OnlineOrder,
  OrderSummaryItem as OnlineOrderSummaryItem,
  GetOfflineTransactionsDownloadLinkQueryVariables,
  GetOfflineTransactionsPageQueryVariables,
  GetOfflineOrderQueryVariables,
  OrdersModifyOrderMutationVariables,
  OrdersGetOrderDataQueryVariables,
} from '@/gql/graphql';
import { ERROR_STRING } from '@/config/constants';
import convertOrderStatusForReport from './convertOrderStatusForReport';

export function getOrders({
  companyId,
  contractId,
  partnerId,
  driverId,
  status,
  query,
  dateFrom,
  dateTo,
  page,
  size,
}: {
  companyId?: string;
  contractId?: string;
  partnerId?: string;
  driverId?: string;
  status?: string;
  query?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  size?: number;
}): Promise<Orders> {
  return api
    .graphql({
      query: q`
        query getOrders (
            $companyId: ID,
            $contractId: ID,
            $partnerId: ID,
            $filter: OrdersQueryParams!,
            $dateFrom: DateTime!,
            $dateTo: DateTime!,
            $status: ReportStatusFilter,
            $reportFileFormat: ReportFormat
        ) {
          cabinet {
              company(id: $companyId, contractId:  $contractId, partnerId: $partnerId) {
                  ordersReportLink(
                      from: $dateFrom,
                      to: $dateTo,
                      status: $status
                      format: $reportFileFormat
                  )
                  orders (filter: $filter) {
                      count
                      summary { fuel count volume total totalDiscount }
                      items {
                      id date refund phone fuel price userPrice payType status statusDetails
                      driver { id name }
                      station { id name address }
                      taxi { id name }
                    carNumber
                      req { volume cost userCost }
                      fact { volume cost userCost }
                      }
                  }
              }
          }
        }
    `,
      variables: {
        companyId,
        contractId,
        filter: {
          query,
          status,
          dateFrom,
          dateTo,
          page,
          size,
          partnerId,
          driverId,
        },
        dateFrom,
        dateTo,
        status: convertOrderStatusForReport(status),
        reportFileFormat: 'excel',
        partnerId,
      },
      token: getAuthToken(),
    })
    .then((result) => {
      const company = result?.cabinet?.company || {};
      return {
        ...(company.orders || {}),
        ordersReportLink: company.ordersReportLink || '',
      };
    });
}

export const GET_ORDERS_REPORT_URL_QUERY = q`
query getOrdersReportUrl (
    $companyId: ID,
    $contractId: ID,
    $partnerId: ID,
    $reportFileFormat: ReportFormat,
    $filter: OrdersQueryParams!
) {
  cabinet {
    company(id: $companyId, contractId: $contractId, partnerId: $partnerId) {
      ordersNewReportLink(
        format: $reportFileFormat
        filter: $filter
      )
    }
  }
}
`;

export function getOrdersReportUrl({
  companyId,
  contractId,
  departmentId,
  dateFrom,
  dateTo,
  status,
  departments,
  query,
  driverId,
  carId,
  showErrors,
}: {
  companyId?: string;
  contractId?: string;
  departmentId?: string;
  dateFrom?: string;
  dateTo?: string;
  status?: string;
  departments?: string[];
  query?: string;
  driverId?: string;
  carId?: string;
  showErrors?: boolean;
}): Promise<string> {
  return api
    .graphql({
      query: GET_ORDERS_REPORT_URL_QUERY,
      variables: {
        companyId,
        contractId,
        partnerId: departmentId,
        filter: {
          showErrors,
          partnerId: departmentId,
          partners: departments,
          driverId,
          carId,
          status: convertOrderStatusForReport(status),
          query,
          dateFrom,
          dateTo,
        },
        reportFileFormat: 'excel',
      },
      token: getAuthToken(),
    })
    .then((result) => result?.cabinet?.company.ordersNewReportLink || '');
}

export type Orders = {
  items: OrderItem[];
  summary: OrderSummaryItem[];
  count: number;
  ordersReportLink: string;
};

export type OrderItem = {
  id: string;
  date: string;
  driver: {
    id: string;
    name: string;
  };
  fact: {
    volume: number;
    cost: number;
    userCost: number;
  };
  fuel: string;
  payType: unknown | null;
  phone: string;
  price: number;
  refund: boolean;
  req: {
    volume: number;
    cost: number;
    userCost: number;
  };
  station: {
    id: string;
    name: string;
    address: string;
  };
  status: string;
  statusDetails: string;
  taxi: unknown | null;
  userPrice: number | null;
};

export type OrderSummaryItem = {
  count: number;
  fuel: string;
  total: number;
  totalDiscount: number;
  volume: number;
  quantity: number;
  discount: number;
};

export type OrdersNewItem = {
  id: string;
  date: string;
  status: string;
  statusMessage: string;
  user: { phone: string };
  corp: {
    driver: {
      name: string;
      phone: string;
    };
    car: {
      number: string;
    };
  };
  station: {
    name: string;
  };
  payment: {
    type: string;
    source: string;
  };
  item?: {
    quantity: number;
    quantityReq: number;
    quantityReport: number;
    cost: {
      fact: number;
      station: number;
      corp: number;
    };
    costReport: {
      fact: number;
      station: number;
      corp: number;
    };
    costReq: {
      fact: number;
      station: number;
      corp: number;
    };
    prices: {
      fact: number;
      station: number;
      corp: number;
    };
    fuel: {
      id: string;
      code: string;
      name: string;
      shortName: string;
    };
    pump: number;
  };
};

export type OrdersNewSummaryItem = {
  name: string;
  count: number;
  quantity: number;
  volume: number;
  total: number;
  discount: number;
};

const QUERY_NEW_ORDER_STATION_ITEM_ONLINE = `
  ... on OnlineOrder {
    item {
      fuel { name }
      quantityReport
      quantityReq
      costReport { fact station corp }
      costReq { fact station corp }
      prices { fact station corp }
      pump
    }
  }
`;

export const GET_COMPANY_ORDERS_QUERY = q`
  query getCompanyOrders ($companyId: ID, $partnerId: ID, $contractId: ID, $ordersFilter: OrdersQueryParams!) {
    cabinet {
      company(id: $companyId, partnerId: $partnerId, contractId: $contractId) {
        ordersNew (filter: $ordersFilter) {
          count
          items {
            id
            date
            status
            statusMessage
            user { phone }
            corp {
              driver {
                name
                phone
              }
              car {
                number
              }
            }
            station { 
              name
              address
            }
            ${QUERY_NEW_ORDER_STATION_ITEM_ONLINE}
          }
          summary {
            name
            count
            quantity
            total
            discount
          }
        }
      }
    }
  }
`;

export function getCompanyOrders({
  companyId,
  contractId,
  departmentId,
  driverId,
  carId,
  filterDateFrom,
  filterDateTo,
  filterStatus,
  filterSearch,
  filterDepartments,
  page,
  pageSize,
  showErrors,
}: {
  companyId?: string;
  contractId?: string;
  departmentId?: string;
  driverId?: string;
  carId?: string;
  filterDateFrom?: string;
  filterDateTo?: string;
  filterSearch?: string;
  filterStatus?: string;
  filterDepartments?: string[];
  page?: number;
  pageSize?: number;
  showErrors?: boolean;
}): Promise<{
  ordersNew: { count: number; items: OnlineOrder[]; summary: OnlineOrderSummaryItem[] };
  summary: Array<OrderSummaryItem>;
}> {
  return api
    .graphql({
      query: GET_COMPANY_ORDERS_QUERY,
      variables: {
        companyId,
        contractId,
        partnerId: departmentId,
        carId,
        ordersFilter: {
          carId,
          showErrors,
          driverId,
          dateFrom: filterDateFrom,
          dateTo: filterDateTo,
          status: filterStatus,
          query: filterSearch,
          partners: filterDepartments,
          page,
          size: pageSize,
        },
      },
      token: getAuthToken(),
    })
    .then(({ cabinet }) => cabinet?.company || {});
}

export async function GetOfflineTransactionsPage(variables: GetOfflineTransactionsPageQueryVariables) {
  const GetOfflineTransactionsPageQuery = graphql(`
    query GetOfflineTransactionsPage($companyId: ID, $contractId: ID, $filter: OrdersQueryParams!) {
      cabinet {
        company(id: $companyId, contractId: $contractId) {
          ordersNew(filter: $filter) {
            count
            items {
              id
              date
              station {
                address
              }
              status
              statusMessage
              ... on OfflineOrder {
                owner {
                  name
                  shortName
                }
                cardNumber
                items {
                  id
                  type
                  quantity
                  groupName
                  shortName
                  prices {
                    corp
                    fact
                    user
                    station
                  }
                  cost {
                    corp
                    fact
                    user
                    station
                  }
                }
              }
            }
            summary {
              name
              count
              quantity
              total
            }
          }
        }
      }
    }
  `);

  const result = await useQuery(GetOfflineTransactionsPageQuery, variables);
  return result.cabinet?.company?.ordersNew;
}

export async function GetOfflineTransactionsDownloadLink(variables: GetOfflineTransactionsDownloadLinkQueryVariables) {
  const GetOfflineTransactionsDownloadLinkQuery = graphql(`
    query GetOfflineTransactionsDownloadLink($companyId: ID, $contractId: ID, $filter: OrdersQueryParams!) {
      cabinet {
        company(id: $companyId, contractId: $contractId) {
          ordersNewReportLink(filter: $filter, format: excel)
        }
      }
    }
  `);

  const result = await useQuery(GetOfflineTransactionsDownloadLinkQuery, variables);
  return result.cabinet?.company?.ordersNewReportLink ?? ERROR_STRING;
}

export async function getOfflineOrder(v: GetOfflineOrderQueryVariables) {
  const q = graphql(`
    query GetOfflineOrder($companyId: ID, $contractId: ID, $orderId: ID!) {
      cabinet {
        company(id: $companyId, contractId: $contractId) {
          orderNew(id: $orderId) {
            id
            date
            station {
              id
              name
              address
              organizationName
            }
            status
            statusMessage
            ... on OfflineOrder {
              mps
              owner {
                name
                shortName
              }
              items {
                id
                type
                quantityReq
                quantityReport
                groupName
                shortName
                prices {
                  corp
                  fact
                  user
                  station
                }
                cost {
                  corp
                  fact
                  user
                  station
                }
              }
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function ordersGetOrderData(variables: OrdersGetOrderDataQueryVariables) {
  const q = graphql(`
    query ordersGetOrderData($fuelUpCompanyId: ID!, $orderId: ID!) {
      cabinet {
        company(id: $fuelUpCompanyId) {
          orderNew(id: $orderId) {
            ... on OnlineOrder {
              station {
                id
                name
                address
              }
              sberSpasiboDetails {
                credit
              }
              item {
                fuel {
                  id
                  name
                }
                quantityReport
                quantityReq
                costReport {
                  fact
                  station
                  corp
                  user
                }
                prices {
                  fact
                  station
                  corp
                }
                discount {
                  station
                  corp
                }
              }
              corp {
                car {
                  id
                  number
                }
                driver {
                  id
                }
                company {
                  id
                }
              }
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, variables);
}

export async function ordersModifyOrder(variables: OrdersModifyOrderMutationVariables) {
  const q = graphql(`
    mutation ordersModifyOrder(
      $orderId: ID!
      $volume: Float
      $discountCorp: Float
      $discountStation: Float
      $carId: ID
      $fuelId: Int
      $stationId: ID
      $commentary: String!
    ) {
      modifyOrder(
        id: $orderId
        volume: $volume
        discountCorp: $discountCorp
        discountStation: $discountStation
        carId: $carId
        fuelId: $fuelId
        stationId: $stationId
        commentary: $commentary
      ) {
        id
      }
    }
  `);

  return (await useQuery(q, variables)).modifyOrder.id;
}
