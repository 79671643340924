import { ActionContext } from 'vuex';
import { RootState, CabinetState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  mutation removeCard ($cardId: ID!) {
    removeCard (id: $cardId)
  }
`;

export default (
  {
    rootState: {
      auth: { token },
    },
  }: ActionContext<CabinetState, RootState>,
  cardId: string,
) =>
  api
    .graphql({
      query,
      variables: { cardId },
      token,
    })
    .then((result) => result.removeCard);
