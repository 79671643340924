import { Module } from 'vuex';
import { createSimpleMutations } from '@/helpers/vuex';
import { getReport, ReportItem } from '@/services/ReportsService';
import toDecimal from '@/helpers/toDecimal';

export default {
  namespaced: true,

  state: (): State => ({
    reportMonthly: [],
    isRequestInProgress: false,
    errorMessage: '',
  }),

  mutations: {
    ...createSimpleMutations<State>(['reportMonthly', 'isRequestInProgress', 'errorMessage']),
  },

  getters: {
    reportMonthly({ reportMonthly }) {
      return reportMonthly.map((item) => ({
        ...item,
        credit: item.credit ? toDecimal({ value: item.credit, suffix: '₽' }) : '',
        debit: item.debit ? toDecimal({ value: item.debit, suffix: '₽' }) : '',
        balance: item.balance ? toDecimal({ value: item.balance, suffix: '₽' }) : '',
      }));
    },

    reportMonthlySummary({ reportMonthly }) {
      const total: { [key: string]: number | string } = reportMonthly.reduce(
        (result, item) => {
          return {
            credit: result.credit + item.credit,
            debit: result.debit + item.debit,
            balance: item.balance,
          };
        },
        { credit: 0, debit: 0, balance: 0 },
      );
      return [
        { title: 'Итого:', dataIndex: '' },
        ...Object.keys(total).map((key) => ({
          title: toDecimal({ value: total[key], suffix: '₽' }),
          dataIndex: key,
        })),
      ];
    },
  },

  actions: {
    async fetchMonthlyReport({ commit }, { month, companyId }: { companyId: string; month: string }) {
      commit('isRequestInProgress', true);
      try {
        const reportMonthly = await getReport({ companyId, month });
        commit('reportMonthly', reportMonthly);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : `Не удалось получить отчет за ${month}`;
        commit('errorMessage', errorMessage);
      } finally {
        commit('isRequestInProgress', false);
      }
    },
  },
} as Module<State, unknown>;

type State = {
  reportMonthly: ReportItem[];
  isRequestInProgress: boolean;
  errorMessage: string;
};
