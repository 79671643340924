import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c029563"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.left > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Отправить повторно через " + _toDisplayString(_ctx.left) + " сек.", 1))
      : (_openBlock(), _createBlock(_component_UiButton, {
          key: 1,
          class: "link",
          type: "link",
          size: "small",
          onClick: _ctx.triggerRepeat
        }, {
          default: _withCtx(() => [
            _createTextVNode("Отправить код повторно")
          ]),
          _: 1
        }, 8, ["onClick"]))
  ]))
}