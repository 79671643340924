
import { defineComponent } from 'vue';
import ACollapsePanel from 'ant-design-vue/es/collapse/CollapsePanel';

export default defineComponent({
  components: {
    ACollapsePanel,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
  },
});
