export default {
  phone: '+{7} (900) 000-0000',
  carNumber: 'a `000 `aa',
  carRegion: '000',
  carNumberWithRegion: 'a 000 aa 00[0]',

  bik: '000000000',
  account: '00000000000000000000',

  price: {
    mask: Number,

    scale: 2,
    thousandsSeparator: ' ',
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: ',',
    mapToRadix: ['.'],

    min: 0,
    max: 999999999,
  },
  maxOrderDiscount: {
    mask: Number,

    scale: 2,
    thousandsSeparator: ' ',
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: ',',
    mapToRadix: ['.'],

    min: 0,
    max: 10,
  },
  priceSigned: {
    mask: Number,

    scale: 2,
    thousandsSeparator: ' ',
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: ',',
    mapToRadix: ['.'],

    min: -999999999,
    max: 999999999,
  },
  discountSigned: {
    mask: Number,

    scale: 2,
    thousandsSeparator: ' ',
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: ',',
    mapToRadix: ['.'],

    min: -99.99,
    max: 99.99,
  },
  balanceAmount: '[0000000][.][00]',
} as const;
