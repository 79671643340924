import { ActionContext } from 'vuex';
import { CabinetState, RootState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  query loadCabinet ($companyId: ID, $contractId: ID, $partnerId: ID) {
    cabinet {
      email
      phone
      companies {
        id
        name
        companyType
        type
        role
        contract { id number }
        contracts { id number type businessType }
        partners {
          id
          name
        }
      }
      company(id: $companyId, contractId: $contractId, partnerId: $partnerId) {
        id name brand companyType type role b2b
        officialAddress postAddress inn kpp ogrn
        contact director phone email
        buh { name phone email }
        tech { name phone email }
        simple
        status
        payments { count balance needMoney }
        drivers { hasAny }
        contract { id type number bank bik account correspondence discount balanceLimit }   
        reportType
        partners {
          id
          name
        }
        partner { id name parent { id name } }
        role
        userAccess {
          partnerParentId
          partnerId
          name
          role
        }
      }
    }    
  }
`;

export default (
  {
    rootState: {
      auth: { token },
    },
    commit,
  }: ActionContext<CabinetState, RootState>,
  { companyId, contractId, departmentId }: { companyId?: string; contractId?: string; departmentId?: string },
) => {
  commit('errorMessage', '');
  return api
    .graphql({
      query,
      variables: {
        companyId,
        contractId,
        partnerId: departmentId,
      },
      token,
    })
    .then(({ cabinet }) => {
      console.log('loadCabinet:success', cabinet);

      commit('setUser', cabinet);
      if (cabinet?.company) {
        commit('setCompany', { ...cabinet.company, hasDrivers: !!cabinet.company.drivers?.hasAny });
      }
      if (cabinet?.companies) {
        commit('companies', cabinet.companies);
      }
      return cabinet;
    })
    .catch((error) => {
      console.error('loadCabinet:error', error);
      commit('errorMessage', `Не удалось загрузить пользователя`);
      return null;
    });
};
