import { defineComponent, h } from 'vue';
import './VLabel.scss';
import { VBtn } from '@/components/Button';
import { VSvgIcon } from '@/components/Images';

export const VLabel = defineComponent({
  name: 'VLabel',
  props: {
    del: Boolean,
    color: {
      type: String,
      default: 'info',
    },
  },
  render() {
    return h('span', { class: `v-label ${this.color} ${this.del ? ' del' : ''}` }, [
      this.$slots.default?.(),
      ...(this.del
        ? [
            h(VBtn, { tile: true, small: true, class: 'ml-2', onClick: () => this.$emit('delete') }, () =>
              h(VSvgIcon, { name: 'close' }),
            ),
          ]
        : []),
    ]);
  },
});
