
import { defineComponent, computed } from 'vue';
import CompanySelect from '@/components/CompanySelect.vue';
import TopPanelBalance from '@/components/TopPanelBalance.vue';
import TopPanelUserMenu from '@/components/TopPanelUserMenu.vue';
import useCompanySelect from '@/AppCompanySelect';
import useCabinetStore from '@/store/CabinetStore';
import BackToAdminButton from '@/components/BackToAdminButton.vue';

export default defineComponent({
  components: {
    CompanySelect,
    TopPanelBalance,
    TopPanelUserMenu,
    BackToAdminButton,
  },

  setup() {
    const { user, company, breadcrumbs, userRole, isDepartment, isBackToAdminAvailable } = useCabinetStore();
    const { selectedCompanyId, isSelectCompanyAvailable, selectCompany } = useCompanySelect();

    return {
      user,
      userRole,
      isSelectCompanyAvailable,
      selectedCompanyId,
      selectCompany,
      breadcrumbs,

      isDepartment,
      isTK: computed(() => company.value?.type === 'tk' && company.value?.simple === false),
      isBackToAdminAvailable,
    };
  },
});
