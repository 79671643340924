import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ACollapse = _resolveComponent("ACollapse")!

  return (_openBlock(), _createBlock(_component_ACollapse, {
    activeKey: _ctx.activeKeyProxy,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKeyProxy) = $event)),
    expandIconPosition: _ctx.expandIconPosition,
    ghost: _ctx.ghost
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["activeKey", "expandIconPosition", "ghost"]))
}