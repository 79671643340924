import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7114588"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stations-map fill-height" }
const _hoisted_2 = { class: "stations-map-type-filter" }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = {
  ref: "mapElement",
  class: "fill-height"
}
const _hoisted_5 = { style: {"position":"absolute","width":"0","height":"0","opacity":"0","overflow":"hidden"} }
const _hoisted_6 = { ref: "stationElement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiCheckbox = _resolveComponent("UiCheckbox")!
  const _component_StationsMapOnlineStation = _resolveComponent("StationsMapOnlineStation")!
  const _component_StationsMapOfflineStation = _resolveComponent("StationsMapOfflineStation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_UiCheckbox, {
          checked: _ctx.isOfflineStationsVisible,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOfflineStationsVisible) = $event)),
          class: "user-select-none"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Оплата на кассе")
          ]),
          _: 1
        }, 8, ["checked"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_UiCheckbox, {
          checked: _ctx.isOnlineStationsVisible,
          "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOnlineStationsVisible) = $event)),
          class: "user-select-none"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Оплата через приложение ")
          ]),
          _: 1
        }, 8, ["checked"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, null, 512),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createVNode(_component_StationsMapOnlineStation, { stationId: _ctx.stationId }, null, 8, ["stationId"]), [
          [_vShow, _ctx.isSelectedStationOnline]
        ]),
        _withDirectives(_createVNode(_component_StationsMapOfflineStation, {
          name: _ctx.stationName,
          address: _ctx.stationAddress
        }, null, 8, ["name", "address"]), [
          [_vShow, !_ctx.isSelectedStationOnline]
        ])
      ], 512)
    ])
  ]))
}