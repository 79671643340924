import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-236071f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createBlock(_component_a_menu, {
    selectedKeys: _ctx.selected,
    "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    openKeys: _ctx.selected,
    "onUpdate:openKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
    class: "elevation-1",
    mode: _ctx.mode
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.value
        }, [
          (item.children?.length)
            ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                key: item.value
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                    return (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: child.value
                    }, {
                      default: _withCtx(() => [
                        (Array.isArray(child.label))
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(child.label, (label) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: label,
                                class: "multiline-item-label"
                              }, _toDisplayString(label), 1))
                            }), 128))
                          : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(child.label), 1))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (!item.children?.length)
            ? (_openBlock(), _createBlock(_component_a_menu_item, {
                key: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["selectedKeys", "openKeys", "mode"]))
}