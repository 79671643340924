
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: false,
    },
    address: {
      type: String,
      required: false,
    },
  },
});
