
import { defineComponent } from 'vue';
import ARadio from 'ant-design-vue/es/radio';

export default defineComponent({
  components: {
    ARadio,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    return {
      check: (value: boolean) => emit('update:modelValue', value),
    };
  },
});
