
import { defineComponent, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import useCabinetStore from '@/store/CabinetStore';
import { getMonthlyReportLink } from '@/services/ReportsService';

const tableColumns = [
  {
    title: 'Дата',
    dataIndex: 'day',
  },
  {
    title: 'Приход',
    dataIndex: 'credit',
  },
  {
    title: 'Расход',
    dataIndex: 'debit',
  },
  {
    title: 'Остаток',
    dataIndex: 'balance',
  },
];

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export default defineComponent({
  props: {
    id: {
      type: String,
      default: '',
    },
    month: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();
    const token = computed(() => store.state.auth.token);
    const { companyId } = useCabinetStore();

    onMounted(() => {
      store.dispatch('reports/fetchMonthlyReport', {
        month: props.month,
        token: token.value,
        companyId: companyId.value,
      });
    });

    const download = async () => {
      const response = await getMonthlyReportLink({ id: companyId.value, month: props.month });
      const url = response.cabinet?.company?.monthlyReportLink;
      if (url) {
        window.open(url, '_blank');
      }
    };

    return {
      tableColumns,
      isRequestInProgress: computed(() => store.state.reports.isRequestInProgress),
      reportMonthly: computed(() => store.getters['reports/reportMonthly']),
      reportMonthlySummary: computed(() => store.getters['reports/reportMonthlySummary']),
      title: computed(() => {
        const matches = props.month?.match(/^(\d{4})-(\d{2})$/);
        if (!matches) {
          return props.month || '';
        }
        return `${MONTHS[Number(matches[2]) - 1] ?? '???'} ${matches[1]}`;
      }),
      download,
    };
  },
});
