import { defineComponent, h, Transition, VNode } from 'vue';
import './VMenu.scss';
import { VMenuArrow } from '@/components/Menu';

export type MenuActivatorSlot = {
  on: {
    click: () => void;
    open: () => void;
    close: (silent: boolean) => void;
  };
};

export default defineComponent({
  props: {
    label: String,
    left: Boolean,
    hideOnScroll: { type: Boolean, default: true },
    inputDisabled: Boolean,
    menuAnimation: { type: String, default: 'fade' },
    duration: [Number, String, Object],
    expandToTop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.onScrollOrResize, { passive: true });
    window.addEventListener('resize', this.onScrollOrResize, { passive: true });
    window.addEventListener('mousedown', this.onMouseDown, { passive: true });
    this.$router.beforeResolve((_, __, next) => {
      this.setActive(false);
      // this.active = false
      next();
    });
  },

  unmounted() {
    window.removeEventListener('scroll', this.onScrollOrResize);
    window.removeEventListener('resize', this.onScrollOrResize);
    window.removeEventListener('mousedown', this.onMouseDown);
  },

  methods: {
    onScrollOrResize({ type }: Event) {
      if (this.active && (type !== 'scroll' || this.hideOnScroll)) {
        this.setActive(false);
        // this.active = false
      }
    },
    onMouseDown(e: MouseEvent) {
      if (!this.active) {
        return;
      }
      let target: Element | null = e.target as Element;
      while (target) {
        if (target === this.$el) {
          return;
        }
        target = target.parentElement;
      }
      this.setActive(false);
      // this.active = false
    },
    setActive(active: boolean, silent = false) {
      if (this.active !== active) {
        this.active = active;
        // console.log('setActive', { active, silent })
        if (!silent) {
          this.$nextTick(() => {
            this.$emit(this.active ? 'open' : 'close');
          });
        }
      }
    },
  },

  render(): VNode {
    const { active } = this;
    return h(
      'div',
      {
        class: { 'v-menu': true, 'v-menu--active': active, 'v-menu--input-disabled': this.inputDisabled },
      },
      [
        ...(this.$slots.activator
          ? [
              this.$slots.activator({
                on: {
                  click: () => {
                    this.setActive(!this.active);
                  },
                  open: () => this.setActive(true),
                  close: (silent = false) => this.setActive(false, silent),
                },
                active,
              }),
            ]
          : [
              h('div', { class: 'v-menu--label', onClick: () => this.setActive(!this.active) }, [
                h('div', { class: 'mr-4' }, this.label || 'LABEL'),
                h(VMenuArrow, { active: this.active }),
              ]),
            ]),
        h(
          Transition,
          {
            name: this.menuAnimation,
          },
          () => [
            active
              ? h(
                  'div',
                  {
                    class: {
                      'v-menu--container': true,
                      'expand-to-top': this.expandToTop,
                      left: this.left,
                      right: !this.left,
                    },
                  },
                  this.$slots.default?.({
                    close: (silent = false) => this.setActive(false, silent),
                  }),
                )
              : undefined,
          ],
        ),
      ],
    );
  },
});
