import { defineComponent, h, VNode } from 'vue';
import './VCard.scss';

export default defineComponent({
  name: 'VCard',
  props: {
    width: [String, Number],
    outlined: Boolean,
  },
  render(): VNode {
    return h(
      'div',
      {
        class: `v-card${this.outlined ? ' v-card--outlined' : ''}`,
        style: { 'max-width': this.width ? `${this.width}px` : undefined },
      },
      [
        ...(this.$slots.header ? [h('header', { class: 'v-card--header' }, this.$slots.header?.())] : []),
        h('div', { class: 'v-card--content' }, this.$slots.default?.()),
      ],
    );
  },
});
