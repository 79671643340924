export const defaultMapSettings = {
  zoom: 8,
  gestureHandling: 'greedy',
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
};

export const googleMapApiSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_KEY}`;

export type GoogleMap = {
  getProjection: () => {
    fromLatLngToPoint: (point: number[]) => { x: number; y: number };
    fromPointToLatLng: (point: number[]) => { x: number; y: number };
  };
  getZoom: () => number;
  setCenter: (position: number[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maps: any;
};

export type GoogleMapInfoWindow = {
  setContent: (element: unknown) => void;
  open: (map: unknown, marker?: unknown) => void;
  setPosition: (position: unknown) => void;
};
