import { defineComponent, h } from 'vue';

export const VDecimal = defineComponent({
  name: 'VDecimal',
  props: {
    value: [String, Number],
    dig: {
      type: [String, Number],
      default: 2,
    },
    empty: Boolean,
    suffix: String,
  },
  render() {
    const dig = Number(this.dig);
    const val = Number(this.value || 0);
    return h(
      'span',
      val || !this.empty
        ? (
            val.toLocaleString('ru', { maximumFractionDigits: dig, minimumFractionDigits: dig }) +
            ' ' +
            (this.suffix || '')
          ).trim()
        : '',
    );
  },
});
