import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default function useCompanySelect() {
  const route = useRoute();
  const store = useStore();
  const selectedCompanyId = ref();
  const selectedCompanyName = computed(() => store.getters['cabinet/company']?.name);
  const selectedId = computed(() => {
    if (Array.isArray(route.params.id)) {
      return route.params.id[0] || '';
    }
    return route.params.id;
  });

  return {
    selectedCompanyId: selectedId,
    selectCompany: (companyId: string) => (selectedCompanyId.value = companyId),
    isSelectCompanyAvailable: computed(() => selectedId.value && selectedCompanyName.value),
  };
}
