import { ActionContext } from 'vuex';
import { RootState, UsersState, api } from '@/store';
import { q } from '@/store/api';
import { Driver } from '@/gql/graphql';

const query = q`
  mutation addDriver ($companyId: ID!, $driver: DriverData!) {
    addDriver (companyId: $companyId, data: $driver) {
      id
    }
  }
`;

export default (
  {
    rootState: {
      cabinet: { company },
      auth: { token },
    },
  }: ActionContext<UsersState, RootState>,
  driver: Driver,
) =>
  api
    .graphql({
      query,
      variables: {
        companyId: company?.id,
        driver,
      },
      token,
    })
    .then((result) => result.addDriver);
