import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ACheckbox = _resolveComponent("ACheckbox")!

  return (_openBlock(), _createBlock(_component_ACheckbox, {
    checked: _ctx.checked,
    disabled: _ctx.disabled,
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:checked', $event.target.checked)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["checked", "disabled"]))
}