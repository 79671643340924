import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ARangePicker = _resolveComponent("ARangePicker")!

  return (_openBlock(), _createBlock(_component_ARangePicker, {
    value: _ctx.defaultRange,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.defaultRange) = $event)),
    modelValue: _ctx.modelValue,
    disabled: _ctx.disabled,
    placeholder: _ctx.placeholder,
    locale: _ctx.localeRu,
    format: _ctx.format,
    inputReadOnly: _ctx.readonly,
    allowClear: _ctx.allowClear,
    showTime: _ctx.showTime ? { format: 'HH:mm' } : undefined,
    onChange: _ctx.update
  }, null, 8, ["value", "modelValue", "disabled", "placeholder", "locale", "format", "inputReadOnly", "allowClear", "showTime", "onChange"]))
}