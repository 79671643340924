import { Module } from 'vuex';
import { RootState, api } from '@/store';
import { q } from '@/store/api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StationsState {}

const query = q`
query getStations {
  stations {
    id
    name
    address
    point
    prices {
      fuel { code }
      price
    }
  }
}
`;

const stations: Module<StationsState, RootState> = {
  namespaced: true,
  state: () => ({}),
  actions: {
    getStations: async () =>
      api
        .graphql({
          query,
        })
        .then((data) => data?.stations || []),
  },
};

export default stations;
