
import { defineComponent } from 'vue';
import StationsMap from '@/components/Blocks/StationsMap.vue';

export default defineComponent({
  components: {
    StationsMap,
  },
  setup() {
    document.querySelector('meta[name="viewport"]')?.setAttribute('content', 'width=device-width, initial-scale=1.0');
  },
});
