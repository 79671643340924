
import { computed, defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { ROUTE_PARAMS_TYPE } from '@/router';
import { hasRouterQuery, removeRouterQuery } from '@/router/flags';
import { useAnalytics } from '@/plugins/analytics';

const ACCESS_DENIED_MESSAGE = 'access_denied';

export default defineComponent({
  name: 'CabinetView',
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const analytics = useAnalytics();
    const router = useRouter();
    const route = useRoute();

    const rootStore = useStore();
    const company = computed(() => rootStore.getters['cabinet/company']);

    /**
     * Проверка изменения компании, при необходимости подгрузка и проверка доступа
     */
    const checkCompanyChanged = async (id: string, routeType: string) => {
      const ids =
        props.type === ROUTE_PARAMS_TYPE.department
          ? [company.value?.partner?.id]
          : [company.value?.id, company.value?.contract?.id];

      const isNotChanged = ids.includes(id);
      if (isNotChanged) {
        return;
      }

      // если изменилась - грузим новую
      await rootStore.dispatch('cabinet/loadCompany', {
        companyId: routeType === ROUTE_PARAMS_TYPE.company ? id : undefined,
        contractId: routeType === ROUTE_PARAMS_TYPE.station ? id : undefined,
        departmentId: routeType === ROUTE_PARAMS_TYPE.department ? id : undefined,
      });

      // теперь проверить роли
      const { type, role, partnersCount } = company.value || {};
      const { types, roles } = route.meta['nav'] || {};
      if ((types === undefined || types.includes(type)) && (roles === undefined || roles.includes(role))) {
        if (!['partners', 'partner'].includes(route.name as string) || partnersCount > 1) {
          return;
        }
      }

      // сюда пришли, что-то не то с доступом
      throw new Error(ACCESS_DENIED_MESSAGE);
    };

    onBeforeRouteUpdate((to, _, next) => {
      /**
       * При обновлении маршрутизатора проверяем доступность выбранной страницы
       */
      const routeId = to.params.id as string;
      checkCompanyChanged(routeId, to.params.type as string)
        .then(() => next())
        .catch((err) => {
          if (err.message === ACCESS_DENIED_MESSAGE) {
            if (to.redirectedFrom) {
              next();
              return;
            }
            const getCompanyRouteTypeById = rootStore.getters['cabinet/companyRouteTypeById'];
            next({ name: 'orders', params: { id: routeId, type: getCompanyRouteTypeById(routeId) } });
          } else {
            next(false);
          }
        });
    });

    onMounted(async () => {
      await checkCompanyChanged(props.id, route.params.type as string).catch((err) => {
        if (err.message === ACCESS_DENIED_MESSAGE) {
          const getCompanyRouteTypeById = rootStore.getters['cabinet/companyRouteTypeById'];
          router.replace({
            name: 'orders',
            params: { id: props.id, type: getCompanyRouteTypeById(props.id) },
          });
        }
      });

      if (hasRouterQuery('loginByPhoneSuccess')) {
        analytics.handle('loginSuccess', { id: props.id, method: 'phone' });
        removeRouterQuery('loginByPhoneSuccess');
      } else if (hasRouterQuery('loginByEmailSuccess')) {
        analytics.handle('loginSuccess', { id: props.id, method: 'email' });
        removeRouterQuery('loginByEmailSuccess');
      } else if (hasRouterQuery('registrationSuccess')) {
        analytics.handle('registrationSuccess', { id: props.id, method: 'phone' });
        removeRouterQuery('registrationSuccess');
      }
    });

    return {
      company,
    };
  },
});
