import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VSvgIcon = _resolveComponent("VSvgIcon")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createBlock(_component_UiButton, {
    class: "button-sber",
    size: "large",
    onClick: _ctx.connect
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VSvgIcon, { name: "sb" }),
      _createTextVNode(" Войти по СберБизнес ID ")
    ]),
    _: 1
  }, 8, ["onClick"]))
}