
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import loadScript from '@/plugins/load-script';
import { Station } from '@/gql/graphql';
import { defaultMapSettings, googleMapApiSrc, GoogleMap } from '@/config/googleMaps';

declare const google: GoogleMap;

export default defineComponent({
  name: 'MapsView',
  setup() {
    const store = useStore();

    const map = ref(null);
    const stations = ref<HTMLDivElement[] | null>(null);
    const stationsList = ref<Station[]>([]);

    const stationsElements = computed(() => {
      const res: { [id: string]: HTMLDivElement } = {};
      (stations.value as HTMLDivElement[])?.forEach((el) => {
        const { id } = el.dataset;
        if (id) {
          res[id] = el;
        }
      });
      return res;
    });

    const loadStations = async () => {
      return store.dispatch('stations/getStations').then((stations) => {
        stationsList.value = stations;
      });
    };

    const loadMap = async () => {
      return loadScript({
        id: 'google-map',
        src: googleMapApiSrc,
      }).then(() => {
        return new google.maps.Map(map.value, {
          center: new google.maps.LatLng(55.75222, 37.61556),
          ...defaultMapSettings,
        });
      });
    };

    onMounted(() => {
      Promise.all([loadMap(), loadStations()]).then(([map]) => {
        const infoWindow = new google.maps.InfoWindow();
        stationsList.value.forEach((station) => {
          if (!station.point) {
            return;
          }
          const marker = new google.maps.Marker({
            map,
            position: new google.maps.LatLng(station.point[0], station.point[1]),
            icon: {
              url: 'https://res.fuelup.ru/imgs/station1.png',
              scaledSize: new google.maps.Size(20, 20),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(0, 0),
            },
          });
          // noinspection JSDeprecatedSymbols
          (marker as any).addListener('click', () => {
            infoWindow.setContent(stationsElements.value[station.id]);
            infoWindow.open(map, marker);
          });
        });
      });
    });

    return {
      map,
      stations,
      stationsList,
      stationsElements,
    };
  },
});
