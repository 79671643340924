import { ActionContext } from 'vuex';
import { RootState, CabinetState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  mutation checkPayment ($id: ID!) {
    checkPayment (id: $id) { id status }
  }
`;

export default (
  {
    rootState: {
      auth: { token },
    },
  }: ActionContext<CabinetState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: string,
) =>
  api
    .graphql({
      query,
      variables: { id },
      token,
    })
    .then((result) => result.checkPayment);
