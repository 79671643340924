
import { defineComponent, PropType } from 'vue';
import AButton from 'ant-design-vue/es/button';
import { SizeType } from 'ant-design-vue/es/config-provider';

export default defineComponent({
  components: {
    AButton,
  },
  props: {
    /**
     * Тип кнопки
     */
    type: {
      type: String as PropType<'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'>,
      default: 'default',
    },
    /**
     * Размер кнопки
     */
    size: {
      type: String as PropType<SizeType>,
      required: false,
    },
    /**
     * Анимация загрузки
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Выключить кнопку
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Опасное действие
     */
    danger: {
      type: Boolean,
      default: false,
    },
  },
});
