import { defineComponent, h } from 'vue';
import dayjs from 'dayjs';

export const VDatetimeString = defineComponent({
  name: 'VDatetimeString',
  props: {
    value: [String],
  },
  render() {
    return this.value ? h('span', dayjs(this.value).format('DD-MM-YYYY HH:mm:ss')) : null;
  },
});
