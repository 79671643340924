
import { defineComponent, computed, PropType } from 'vue';
import AUpload, { UploadFile } from 'ant-design-vue/es/upload';
import { UploadRequestOption } from 'ant-design-vue/es/vc-upload/interface';

export default defineComponent({
  components: {
    AUpload,
  },

  props: {
    modelValue: {
      type: Array as PropType<UploadFile<unknown>[]>,
      required: true,
    },
    customRequest: {
      type: Function as PropType<(opts: UploadRequestOption<unknown>) => Promise<void>>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const fileList = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      fileList,
    };
  },
});
