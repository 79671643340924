import { defineComponent, h, Transition } from 'vue';
import { RouterLink } from 'vue-router';
import { VSvgIcon } from '@/components/Images';
import { VMenuArrow } from '@/components/Menu';
import './VMenuItem.scss';

export default defineComponent({
  name: 'VMenuItem',
  props: {
    icon: String,
    to: [String, Object],
    modelValue: Boolean,
  },
  data() {
    return {
      expanded: !this.to && !!this.modelValue,
    };
  },

  render() {
    const title = [
      ...(this.icon ? [h(VSvgIcon, { name: this.icon })] : []),
      h('div', { class: 'v-menu-item--title-slot' }, this.$slots.default?.()),
      ...(this.$slots.items ? [h(VMenuArrow, { class: 'mr-2', active: this.expanded })] : []),
    ];

    return h('div', { class: 'v-menu-item' }, [
      ...(this.to && !this.$slots.items
        ? [
            h(
              RouterLink as never,
              {
                to: this.to,
                custom: true,
              },
              {
                // eslint-disable-next-line
                default: ({ href, isExactActive, navigate }: any) => {
                  return h(
                    'a',
                    {
                      class: `v-menu-item--title${isExactActive || this.$route.path.includes(href) ? ' active' : ''}`,
                      href: href,
                      onClick: navigate,
                    },
                    title,
                  );
                },
              },
            ),
          ]
        : [
            h(
              'div',
              {
                class: `v-menu-item--title${this.expanded ? ' expanded' : ''}`,
                ...(this.$slots.items ? { onClick: this.toggleExpanded } : {}),
              },
              title,
            ),
          ]),
      ...(this.$slots.items
        ? [
            h(
              Transition,
              {
                // duration: 2000,
                name: 'menu-items',
                onEnter: this.onEnter,
                onAfterEnter: this.onAfterEnter,
                onLeave: this.onLeave,
              },
              () => (this.expanded ? [h('div', { class: 'v-menu-item--subitems' }, this.$slots?.items?.())] : []),
            ),
          ]
        : []),
    ]);
  },
  methods: {
    onEnter(el: Element) {
      const { height } = getComputedStyle(el);
      (el as HTMLElement).style.height = '0';
      getComputedStyle(el).height;
      requestAnimationFrame(() => {
        (el as HTMLElement).style.height = height;
      });
    },
    onAfterEnter(el: Element) {
      (el as HTMLElement).style.height = 'auto';
    },
    onLeave(element: Element) {
      (element as HTMLElement).style.height = getComputedStyle(element).height;
      getComputedStyle(element).height;
      requestAnimationFrame(() => {
        (element as HTMLElement).style.height = '0';
      });
    },
    toggleExpanded() {
      this.$emit('update:modelValue', (this.expanded = !this.expanded));
    },
  },
});
