
import { defineComponent } from 'vue';
import ASwitch from 'ant-design-vue/es/switch';

export default defineComponent({
  components: { ASwitch },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    return {
      change: (value: unknown) => emit('update:modelValue', value),
    };
  },
});
