import { ActionContext } from 'vuex';
import { api, CabinetState, RootState } from '@/store';
import { Fuels } from '@/store/cabinet';
import { q } from '@/store/api';

const query = q`
  query getInfo {
    fuel { 
      id 
      name 
      group { id }
    }
    regions { id name }
    fuelGroups { id name }
  }
`;

export default ({ commit, state: { fuel, regions } }: ActionContext<CabinetState, RootState>) => {
  if (fuel?.length && regions?.length) {
    return { fuel, regions };
  }
  return api.graphql({ query }).then(({ fuel, fuelGroups, regions }: Fuels) => {
    commit('updateInfo', { fuel, fuelGroups, regions });
    return { fuel, regions };
  });
};
