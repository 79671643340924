
import { defineComponent, PropType } from 'vue';
import { BreadcrumbItem } from './UiBreadcrumbs.vue';

export default defineComponent({
  inheritAttrs: false,

  props: {
    title: {
      type: String,
      required: false,
    },
    breadcrumbs: {
      type: Array as PropType<BreadcrumbItem[]>,
      default: () => [],
    },
  },
});
