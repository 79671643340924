import { defineComponent, h, withModifiers } from 'vue';

export default defineComponent({
  name: 'VForm',
  render() {
    return h(
      'form',
      { class: 'v-form', onSubmit: withModifiers(this.onSubmit, ['prevent', 'stop']) },
      this.$slots?.default?.(),
    );
  },
  methods: {
    onSubmit() {
      // console.log('FORM SUBMIT');
    },
  },
});
