import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfc399d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pd-6"
}
const _hoisted_2 = {
  key: 1,
  class: "mt-8 pd-6 text--center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TopPanel = _resolveComponent("TopPanel")!
  const _component_TopPanelMobile = _resolveComponent("TopPanelMobile")!
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_SideMenuMobile = _resolveComponent("SideMenuMobile")!
  const _component_VApp = _resolveComponent("VApp")!
  const _component_UiAlert = _resolveComponent("UiAlert")!
  const _component_UiSpin = _resolveComponent("UiSpin")!
  const _component_auth_view = _resolveComponent("auth-view")!

  return (_ctx.$route.meta.public)
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0,
        name: "default"
      }))
    : (_ctx.isAuthorized)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isLoaded)
            ? (_openBlock(), _createBlock(_component_VApp, { key: 0 }, {
                header: _withCtx(() => [
                  _createVNode(_component_TopPanel, { class: "visible-on-desktop" }),
                  _createVNode(_component_TopPanelMobile, {
                    class: "visible-on-mobile",
                    onToggleMenu: _ctx.toggleMobileMenu
                  }, null, 8, ["onToggleMenu"])
                ]),
                drawer: _withCtx(() => [
                  _createVNode(_component_SideMenu, {
                    id: _ctx.id,
                    class: "visible-on-desktop",
                    type: _ctx.type
                  }, null, 8, ["id", "type"]),
                  (_ctx.isMobileMenuVisible)
                    ? (_openBlock(), _createBlock(_component_SideMenuMobile, {
                        key: 0,
                        class: "visible-on-mobile side-menu-mobile",
                        onClose: _ctx.toggleMobileMenu
                      }, null, 8, ["onClose"]))
                    : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_router_view, { name: "default" })
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.errorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_UiAlert, {
                        type: "error",
                        message: _ctx.errorMessage
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.errorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_UiSpin)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
        ], 64))
      : (_openBlock(), _createBlock(_component_auth_view, { key: 2 }))
}