import { ActionContext } from 'vuex';
import { createSimpleMutations, createSimpleGetters } from '@/helpers/vuex';
import { RootState } from '@/store';
import toDecimal from '@/helpers/toDecimal';
import { getOrders, Orders, OrderSummaryItem } from '@/services/OrdersService';

export interface OrdersState {
  orders: Orders;
  ordersReportLink: string;
  isRequestInProgress: boolean;
  errorMessage: string;
}

export default {
  namespaced: true,
  state: () => ({
    orders: {},
    isRequestInProgress: false,
    errorMessage: '',
  }),
  getters: {
    ...createSimpleGetters<OrdersState>(['isRequestInProgress']),

    fuelsSummary({ orders }: OrdersState) {
      if (!orders.summary) {
        return [];
      }
      return orders.summary
        .filter(({ volume }) => volume > 0)
        .map((i) => {
          const item = Object.keys(i).reduce((result, key) => {
            result[key] = toDecimal({ value: i[key as keyof OrderSummaryItem] });
            return result;
          }, {} as { [key: string]: string });
          return item;
        });
    },
    fuelsSummaryTotal({ orders }: OrdersState) {
      if (!orders.summary) {
        return [];
      }
      const summary = orders.summary.filter(({ volume }) => volume > 0);
      const total: { [key: string]: number | string } = summary.reduce(
        (res, row) => {
          res.volume += row.volume || 0;
          res.total += row.total || 0;
          res.totalDiscount += row.totalDiscount || 0;
          return res;
        },
        { volume: 0, total: 0, totalDiscount: 0 },
      );
      return [
        { title: 'Итого', dataIndex: '' },
        ...Object.keys(total).map((key) => ({
          title: toDecimal({ value: total[key] }),
          dataIndex: key,
          align: 'right',
        })),
      ];
    },

    ordersReportUrl({ orders }: OrdersState) {
      return orders.ordersReportLink || '';
    },
  },
  mutations: {
    ...createSimpleMutations<OrdersState>(['isRequestInProgress', 'orders', 'errorMessage']),
  },
  actions: {
    async fetchOrders(
      { commit, state }: ActionContext<OrdersState, RootState>,
      {
        companyId,
        contractId,
        partnerId,
        driverId,
        query: queryString,
        status,
        page,
        size,
        dateFrom,
        dateTo,
      }: GetOrdersParams,
    ) {
      commit('isRequestInProgress', true);
      try {
        const orders = await getOrders({
          companyId,
          contractId,
          partnerId,
          driverId,
          status,
          query: queryString,
          dateFrom,
          dateTo,
          page,
          size,
        });

        // если не первая страница, то бэк вернёт число заказов
        if (!orders.count) {
          orders.count = state.orders.count;
        }
        // если не первая страница, то бэк вернёт общую сумму заказов
        if (!orders.summary?.length) {
          orders.summary = state.orders.summary;
        }

        commit('orders', orders);
      } catch (error) {
        commit('errorMessage', `Не удалось получить список заказов`);
      } finally {
        commit('isRequestInProgress', false);
      }
    },

    downloadReport({ state: { orders } }: ActionContext<OrdersState, RootState>) {
      const url = orders.ordersReportLink || '';
      window?.open(url, '_blank');
    },
  },
};

type GetOrdersParams = {
  companyId?: string;
  contractId?: string;
  partnerId?: string;
  driverId?: string;
  query?: string;
  status?: string;
  page?: number;
  size?: number;
  dateFrom?: string;
  dateTo?: string;
};
