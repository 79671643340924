
import { PropType, defineComponent } from 'vue';
import ATypography from 'ant-design-vue/es/typography';

export type TextType = 'secondary' | 'success' | 'warning' | 'danger' | undefined;

export default defineComponent({
  components: {
    ATypographyText: ATypography.Text,
  },

  props: {
    type: {
      type: String as PropType<TextType>,
      required: false,
    },
  },
});
