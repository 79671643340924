/* eslint-disable @typescript-eslint/no-explicit-any */

import './Ripple.scss';

const transform = (el: HTMLElement, value: any): void => {
  el.style['transform'] = value;
  el.style['webkitTransform'] = value;
};

const opacity = (el: HTMLElement | any, value: any) => {
  el.style['opacity'] = value.toString();
};

const isTouchEvent = (e: Event) => e.constructor.name === 'TouchEvent';

const calculate = (e: Event | any, el: HTMLElement | any, value: any = {}) => {
  const offset = el.getBoundingClientRect();
  const target = isTouchEvent(e) ? e.touches[e.touches.length - 1] : e;
  const localX = target.clientX - offset.left;
  const localY = target.clientY - offset.top;

  let radius;
  let scale = 0.3;
  if (el._ripple && el._ripple.circle) {
    scale = 0.15;
    radius = el.clientWidth / 2;
    radius = value.center ? radius : radius + Math.sqrt((localX - radius) ** 2 + (localY - radius) ** 2) / 4;
  } else {
    radius = Math.sqrt(el.clientWidth ** 2 + el.clientHeight ** 2) / 2;
  }

  const centerX = `${(el.clientWidth - radius * 2) / 2}px`;
  const centerY = `${(el.clientHeight - radius * 2) / 2}px`;

  const x = value.center ? centerX : `${localX - radius}px`;
  const y = value.center ? centerY : `${localY - radius}px`;

  return { radius, scale, x, y, centerX, centerY };
};

const ripples = {
  show(e: Event, el: HTMLElement | any, value: any = {}) {
    if (!el._ripple || !el._ripple.enabled) {
      return;
    }

    const container = document.createElement('span');
    const animation = document.createElement('span');

    container.appendChild(animation);
    container.className = 'v-ripple__container';

    if (value.class) {
      container.className += ` ${value.class}`;
    }

    const { radius, scale, x, y, centerX, centerY } = calculate(e, el, value);

    const size = `${radius * 2}px`;
    animation.className = 'v-ripple__animation';
    animation.style.width = size;
    animation.style.height = size;

    el.appendChild(container);

    const computed = window.getComputedStyle(el);
    if (computed && computed.position === 'static') {
      el.style.position = 'relative';
      el.dataset.previousPosition = 'static';
    }

    animation.classList.add('v-ripple__animation--enter');
    animation.classList.add('v-ripple__animation--visible');
    transform(animation, `translate(${x}, ${y}) scale3d(${scale},${scale},${scale})`);
    opacity(animation, 0);
    animation.dataset.activated = String(performance.now());

    setTimeout(() => {
      animation.classList.remove('v-ripple__animation--enter');
      animation.classList.add('v-ripple__animation--in');
      transform(animation, `translate(${centerX}, ${centerY}) scale3d(1,1,1)`);
      opacity(animation, 0.15);
    }, 0);
  },

  hide(el: HTMLElement | any) {
    if (!el || !el._ripple || !el._ripple.enabled) {
      return;
    }

    const ripples = el.getElementsByClassName('v-ripple__animation');

    if (ripples.length === 0) {
      return;
    }
    const animation = ripples[ripples.length - 1];

    if (animation.dataset.isHiding) {
      return;
    }
    animation.dataset.isHiding = 'true';

    const diff = performance.now() - Number(animation.dataset.activated);
    const delay = Math.max(250 - diff, 0);

    setTimeout(() => {
      animation.classList.remove('v-ripple__animation--in');
      animation.classList.add('v-ripple__animation--out');
      opacity(animation, 0);
      setTimeout(() => {
        const ripples = el.getElementsByClassName('v-ripple__animation');
        if (ripples.length === 1 && el.dataset.previousPosition) {
          el.style.position = el.dataset.previousPosition;
          delete el.dataset.previousPosition;
        }
        animation.parentNode && el.removeChild(animation.parentNode);
      }, 300);
    }, delay);
  },
};

class Ripple {
  el: HTMLElement | any;
  enabled = false;
  centered?: boolean;
  class?: string;
  circle?: any;
  constructor(el: HTMLElement, binding: any) {
    this.el = el;
    this.update(binding);
  }
  update(binding: any, checkOldValue = false) {
    const { el } = this;

    const isRippleEnabled = (value: any | undefined) => typeof value === 'undefined' || !!value;

    const wasEnabled = checkOldValue ? isRippleEnabled(binding.oldValue) : false;

    this.enabled = isRippleEnabled(binding.value);
    if (!this.enabled) {
      ripples.hide(el);
    }

    const value = binding.value || {};

    if (value.center) {
      this.centered = true;
    }
    if (value.class) {
      this.class = binding.value.class;
    }
    if (value.circle) {
      this.circle = value.circle;
    }

    if (this.enabled && !wasEnabled) {
      this.addListeners();
    } else if (!this.enabled && wasEnabled) {
      this.removeListeners();
    }
  }
  unbind() {
    this.removeListeners();
  }
  addListeners() {
    const { el, rippleShow, rippleHide } = this;
    el.addEventListener('touchstart', rippleShow, { passive: true });
    el.addEventListener('touchend', rippleHide, { passive: true });
    el.addEventListener('touchcancel', rippleHide);
    el.addEventListener('mousedown', rippleShow);
    el.addEventListener('mouseup', rippleHide);
    el.addEventListener('mouseleave', rippleHide);
    el.addEventListener('dragstart', rippleHide, { passive: true });
  }
  removeListeners() {
    const { el, rippleShow, rippleHide } = this;
    el.removeEventListener('mousedown', rippleShow);
    el.removeEventListener('touchstart', rippleShow);
    el.removeEventListener('touchend', rippleHide);
    el.removeEventListener('touchcancel', rippleHide);
    el.removeEventListener('mouseup', rippleHide);
    el.removeEventListener('mouseleave', rippleHide);
    el.removeEventListener('dragstart', rippleHide);
  }
  rippleShow(e: Event) {
    const value: any = {};
    const element: HTMLElement | any = e.currentTarget;
    let clickElement: HTMLElement | any = e.target;
    if (clickElement && element) {
      while (clickElement && clickElement !== element) {
        if (clickElement._stopRipple) {
          return;
        }
        clickElement = clickElement.parentElement;
      }
    }
    if (!element || !element._ripple || element._ripple.touched) {
      return;
    }
    if (isTouchEvent(e)) {
      element._ripple.touched = true;
      element._ripple.isTouch = true;
    } else {
      /*
       * It's possible for touch events to fire
       * as mouse events on Android/iOS, this
       * will skip the event call if it has
       * already been registered as touch
       */
      if (element._ripple.isTouch) {
        return;
      }
    }
    value.center = element._ripple.centered;
    if (element._ripple.class) {
      value.class = element._ripple.class;
    }
    ripples.show(e, element, value);
  }
  rippleHide(e: Event) {
    const element: HTMLElement | any = e.currentTarget || null;
    if (!element) {
      return;
    }
    window.setTimeout(() => {
      if (element._ripple) {
        element._ripple.touched = false;
      }
    });
    ripples.hide(element);
  }
}

// directive
export default {
  beforeMount: (el: any, binding: any): void => {
    el._ripple = new Ripple(el, binding);
  },
  unmounted: (el: any) => {
    el._ripple?.unbind();
    delete el._ripple;
  },
  updated: (el: any, binding: any) => {
    if (binding.value !== binding.oldValue) {
      el._ripple.update(binding, true);
    }
  },
};
