
import { PropType, defineComponent } from 'vue';
import APopover from 'ant-design-vue/es/popover';
import { TriggerType } from 'ant-design-vue/es/tooltip/abstractTooltipProps';

export default defineComponent({
  components: {
    APopover,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    trigger: {
      type: String as PropType<TriggerType>,
      default: 'click',
    },
  },
});
