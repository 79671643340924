import { Module } from 'vuex';
import jwtDecode from 'jwt-decode';
import { RootState } from '@/store';
import ls from '@/plugins/ls';
import * as actions from './actions';

export const ACCESS_TOKEN_KEY = 'fuelup_token';

export type AuthResponse = {
  token: string;
};

export interface AuthState {
  token: string | null;
}

const auth: Module<AuthState, RootState> = {
  namespaced: true,

  state: () => ({
    token: null,
    user: null,
  }),

  getters: {
    isAuthorized: (state: AuthState) => !!state.token,
    payload: (state: AuthState) => (state.token ? jwtDecode(state.token) : null),
  },

  actions,

  mutations: {
    load: (state: AuthState) => {
      ls.on(ACCESS_TOKEN_KEY, (value) => {
        state.token = value;
      });
      state.token = getAuthToken();
    },
    setToken: (state: AuthState, token: string | null) => {
      state.token = token;
      if (token) {
        ls.set(ACCESS_TOKEN_KEY, token);
      } else {
        ls.remove(ACCESS_TOKEN_KEY);
      }
    },
  },
};

export default auth;

export function getAuthToken() {
  return ls.get(ACCESS_TOKEN_KEY) || '';
}
