import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiHeader = _resolveComponent("UiHeader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_UiHeader, {
            key: 0,
            title: _ctx.title,
            breadcrumbs: _ctx.breadcrumbs
          }, null, 8, ["title", "breadcrumbs"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("main", _mergeProps(_ctx.$attrs, { class: "mg-6" }), [
      _renderSlot(_ctx.$slots, "default")
    ], 16)
  ], 64))
}