
import { PropType, defineComponent } from 'vue';
import ATypography from 'ant-design-vue/es/typography';

export default defineComponent({
  components: {
    ATypographyTitle: ATypography.Title,
  },
  props: {
    type: {
      type: String as PropType<'secondary' | 'success' | 'warning' | 'danger'>,
      required: false,
    },
    level: {
      type: Number as PropType<1 | 2 | 3 | 4 | 5>,
      default: 1,
    },
  },
});
