import { defineComponent, h } from 'vue';
import './VMenuArrow.scss';

export default defineComponent({
  name: 'VMenuArrow',
  props: {
    active: Boolean,
  },
  render() {
    return h('i', { class: `v-menu-arrow${this.active ? ' active' : ''}` });
  },
});
