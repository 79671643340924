
import { defineComponent, computed, PropType, ref } from 'vue';
import ATreeSelect from 'ant-design-vue/es/tree-select';
import ATag from 'ant-design-vue/es/tag';
import { CheckedStrategy } from 'ant-design-vue/es/vc-tree-select/utils/strategyUtil';
import { LabeledValueType } from 'ant-design-vue/es/vc-tree-select/TreeSelect';
import type { TreeSelectProps } from 'ant-design-vue';

export default defineComponent({
  components: {
    ATreeSelect,
    ATag,
  },
  props: {
    /**
     * Выбранные значения. По умолчанию string[], но, если
     * значение treeCheckStrictly или labelInValue равно true, то LabeledValueType
     */
    modelValue: {
      type: Array as PropType<string[] | LabeledValueType[]>,
      default: () => [],
    },
    items: {
      type: Array as PropType<SelectTreeItems>,
      required: true,
    },
    treeCheckable: {
      type: Boolean,
      default: false,
    },
    treeNodeFilterProp: {
      type: String,
      default: 'title',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Возможность удалить выбранные пункты, не открывая меню.
     * Данный проп работает только если typeof modelValue === 'string',
     * для LabeledValueType нужна доработка
     */
    tagRemoveIconVisible: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    treeCheckStrictly: {
      type: Boolean,
      default: false,
    },
    treeCheckableSelectMode: {
      type: String as PropType<CheckedStrategy>,
      default: 'SHOW_CHILD',
    },
    popupClassName: {
      type: String,
      default: '',
    },
    treeDefaultExpandAll: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Выбрать',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const valueProxy = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const isOpened = ref(false);
    const showCheckedStrategy = computed(() => {
      if (props.treeCheckStrictly) {
        return ATreeSelect.SHOW_ALL;
      }
      return props.treeCheckable ? props.treeCheckableSelectMode : ATreeSelect.SHOW_ALL;
    });

    // работает только для string[]
    const removeItem = (valueToRemove: string) => {
      emit(
        'update:modelValue',
        (valueProxy.value as string[]).filter((v) => v !== valueToRemove),
      );
    };

    return {
      isOpened,
      valueProxy,
      showCheckedStrategy,
      removeItem,
    };
  },
});

export type SelectTreeItems = TreeSelectProps['treeData'];
