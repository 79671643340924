
import { defineComponent } from 'vue';
import ACheckbox from 'ant-design-vue/es/checkbox';

export default defineComponent({
  components: { ACheckbox },
  props: {
    /**
     * Выключить флажок
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Флажок выбран
     */
    checked: {
      type: Boolean,
      default: false,
    },
  },
});
