
/* eslint-disable @typescript-eslint/no-explicit-any */

import { defineComponent, ref, computed, watch, nextTick, onMounted, onUnmounted } from 'vue';
import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { loadRecaptcha, setBadgeVisible } from '@/plugins/recaptcha';
import { setWelcomeFlag, removeWelcomeFlag } from '@/services/RegistrationService';
import { addRouterQuery } from '@/router/flags';
import FuelupLogo from '@/views/components/FuelupLogo.vue';
import SvgOk from '@/views/auth/components/SvgOk.vue';
import { useAnalytics } from '@/plugins/analytics';
import AuthByEmail from './AuthByEmail.vue';
import AuthByPhone from './AuthByPhone.vue';
import EnterPinCode from './EnterPinCode.vue';
import RestorePassword from './RestorePassword.vue';
import RegisterByPhone from './RegisterByPhone.vue';
import NewPassword from './NewPassword.vue';

export default defineComponent({
  components: {
    SvgOk,
    FuelupLogo,
    AuthByEmail,
    AuthByPhone,
    EnterPinCode,
    RestorePassword,
    RegisterByPhone,
    NewPassword,
  },

  setup() {
    const analytics = useAnalytics();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const hasSignInError = ref(false);

    const email = ref('');
    const password = ref('');
    const updateEmail = (value: string) => {
      email.value = value;
      hasSignInError.value = false;
    };
    const updatePassword = (value: string) => {
      password.value = value;
      hasSignInError.value = false;
    };

    const phone = ref('');
    const updatePhone = (value: string) => {
      phone.value = value;
    };

    const pinLeft = ref(0);
    const isPinTimeOver = computed(() => pinLeft.value < 0);
    const updatePin = () => {
      hasSignInError.value = false;
    };

    const step = ref('email');
    const progress = ref(false);

    const strict = ref(true);
    const isRegistrationPinRequest = ref(false);
    const tooManyRequest = ref(false);
    const temporaryBanned = ref(false);
    const lastAttempt = ref(false);

    const token = ref('');
    const pinTimer = ref<ReturnType<typeof setInterval> | number>(0);

    const isRegistration = computed(() => route.name === 'registration');
    const restoreDisabled = computed(() => progress.value || !email.value);
    const clearPhone = computed(() => phone.value?.replace(/\D+/g, '')?.substring(0, 11) || '');
    const phoneDisabled = computed(() => progress.value || clearPhone.value.length !== 11);
    const tokenPayload = computed<{ phone: string; exp: number; lock: number } | null>(() =>
      token.value ? jwtDecode(token.value) : null,
    );
    const tokenPhone = computed(
      () => tokenPayload.value?.phone?.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5') ?? '',
    );

    const newToken = String(route.query?.t || '');
    if (newToken) {
      token.value = newToken;
      step.value = 'restore_next_step';
      nextTick(() => {
        router.replace({ ...route, query: { ...route.query, t: undefined } }).catch(() => null);
        startTimer();
      });
    } else if (isRegistration.value) {
      step.value = 'registration';
      strict.value = false;
    }

    watch(
      () => step,
      () => {
        if (step.value === 'pin') {
          analytics.handle('seeSmsCodeForm');
        } else {
          stopTimer();
        }
      },
    );

    onMounted(() => {
      removeWelcomeFlag(router);
      analytics?.handle?.('openAuth');
      loadRecaptcha();
      setBadgeVisible(true);
      if (route.name === 'sbidReturn') {
        step.value = 'sberbizid';
        sberbizSubmit();
      } else if (isRegistration.value) {
        isRegistrationPinRequest.value = true;
      }
    });

    onUnmounted(() => {
      setBadgeVisible(false);
    });

    const gotoEmail = () => {
      removeWelcomeFlag(router);
      email.value = '';
      password.value = '';
      hasSignInError.value = false;
      step.value = 'email';
    };

    const gotoPhone = () => {
      phone.value = '';
      hasSignInError.value = false;
      tooManyRequest.value = false;
      isRegistrationPinRequest.value = false;
      step.value = 'phone';
      strict.value = true;
    };

    const gotoRestore = () => {
      email.value = '';
      hasSignInError.value = false;
      tooManyRequest.value = false;
      step.value = 'restore';
    };

    const gotoRegistration = () => {
      phone.value = '';
      strict.value = false;
      hasSignInError.value = false;
      isRegistrationPinRequest.value = true;
      step.value = 'registration';
    };

    const authByEmail = ({ email, password }: { email: string; password: string }) => {
      analytics.handle('clickLoginButton');
      progress.value = true;
      store
        .dispatch('auth/signIn', {
          email,
          password,
        })
        .then(() => {
          addRouterQuery('loginByEmailSuccess');
        })
        .catch((error) => {
          hasSignInError.value = true;
          if (error instanceof AxiosError) {
            temporaryBanned.value = error.response?.data?.message.includes('Request looks bad');
            lastAttempt.value = error.response?.data?.message.includes('Banned');
          } else {
            console.error('ERROR', error.message);
          }
        })
        .finally(() => {
          progress.value = false;
          analytics.handle('clickLoginButton', {
            errorMessage: getAuthByEmailErrorMessage({
              signInError: hasSignInError.value,
              temporaryBanned: temporaryBanned.value,
              lastAttempt: lastAttempt.value,
            }),
          });
        });
    };

    const authByPhone = (isRegistration = false) => {
      if (isRegistration) {
        analytics.handle('clickRegistrationButton');
        setWelcomeFlag(router);
      } else {
        analytics.handle('clickLoginButton', {
          errorMessage: getAuthByPhoneErrorMessage({
            signInError: hasSignInError.value,
            temporaryBanned: temporaryBanned.value,
            tooManyRequest: tooManyRequest.value,
            lastAttempt: lastAttempt.value,
          }),
        });
      }
      hasSignInError.value = false;
      progress.value = true;
      store
        .dispatch('auth/pinRequest', {
          phone: clearPhone.value,
          strict: strict.value,
          utm: route.query['source'] || route.query['utm_source'],
        })
        .then((response) => {
          token.value = response.token;
          step.value = 'pin';
          startTimer();
          if (isRegistrationPinRequest.value) {
            analytics?.handle?.('registrationBegin');
          }
        })
        .catch((error) => {
          step.value = 'phone';
          hasSignInError.value = true;

          if (error instanceof AxiosError) {
            temporaryBanned.value = error.response?.data?.message.includes('Request looks bad');
            lastAttempt.value = error.response?.data?.message.includes('Banned');
            tooManyRequest.value = error.response?.data?.httpStatus === 429;
          } else {
            console.error('ERROR', error.message);
          }
        })
        .finally(() => {
          progress.value = false;
        });
    };

    const restore = (email: string) => {
      progress.value = true;
      store
        .dispatch('auth/restore', email)
        .then(() => {
          step.value = 'restore_sent';
        })
        .catch((error) => {
          hasSignInError.value = true;
          if (error instanceof AxiosError) {
            console.error('AXIOS', error.response?.status, error.response?.data);
          } else {
            console.error('ERROR', error.message);
          }
        })
        .finally(() => {
          progress.value = false;
        });
    };

    const restoreSubmit = () => {
      progress.value = true;
      store
        .dispatch('auth/restoreSubmit', { token: token.value, password: password.value })
        .then(() => {
          step.value = 'restore_success';
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            console.error('AXIOS', error.response?.status, error.response?.data);
          } else {
            console.error('ERROR', error.message);
          }
        })
        .finally(() => {
          progress.value = false;
        });
    };

    const pinSubmit = (pin: string) => {
      progress.value = true;
      store
        .dispatch('auth/pinSubmit', { token: token.value, pin })
        .then(() => {
          // если это была регистрация
          if (isRegistrationPinRequest.value) {
            router
              .replace({
                ...route,
                query: {
                  ...route.query,
                  source: undefined,
                  utm_source: undefined,
                  utm_medium: undefined,
                  utm_campaign: undefined,
                  registrationSuccess: 'true',
                },
              })
              .catch(() => null);
            return;
          }

          // иначе отмечаем, что это была авторизация по номеру телефона
          addRouterQuery('loginByPhoneSuccess');
        })
        .catch((error) => {
          setTimeout(() => {
            hasSignInError.value = true;
          }, 20);
          if (error instanceof AxiosError) {
            temporaryBanned.value = error.response?.data?.message.includes('Request looks bad');
            lastAttempt.value = error.response?.data?.message.includes('Banned');
          } else {
            console.error('ERROR', error.message);
          }
        })
        .finally(() => {
          progress.value = false;
        });
    };

    const startTimer = () => {
      stopTimer();
      timer();
      pinTimer.value = setInterval(() => {
        timer();
      }, 1000);
    };

    const stopTimer = () => {
      clearInterval(pinTimer.value);
    };

    const timer = () => {
      pinLeft.value = (tokenPayload.value?.exp || 0) - Math.floor(+new Date() / 1000);
      if (isPinTimeOver.value) {
        stopTimer();
      }
    };

    const sberbizSubmit = () => {
      const { code, state } = route.query;
      store.dispatch('auth/sbidCode', { code, state });
    };

    return {
      hasSignInError,

      email,
      password,
      updateEmail,
      updatePassword,

      phone,
      updatePhone,

      pinLeft,
      isPinTimeOver,
      updatePin,

      // текущий шаг
      step,
      progress,

      // вход по телефону
      strict,
      isRegistrationPinRequest,
      tooManyRequest,
      temporaryBanned,
      lastAttempt,

      // пин-код
      token,
      pinTimer,

      // остальное
      restoreDisabled,
      gotoPhone,
      gotoRegistration,
      gotoRestore,
      authByEmail,
      gotoEmail,
      restore,
      restoreSubmit,
      phoneDisabled,
      authByPhone,
      tokenPhone,
      tokenPayload,
      pinSubmit,
    };
  },
});

function getAuthByEmailErrorMessage({
  signInError,
  temporaryBanned,
  lastAttempt,
}: {
  signInError: boolean;
  temporaryBanned: boolean;
  lastAttempt: boolean;
}) {
  if (!signInError) {
    return '';
  }
  if (temporaryBanned) {
    return 'Вы исчерпали все попытки. Попробуйте позже';
  }
  if (lastAttempt) {
    return 'Неверный логин или пароль Вы исчерпали все попытки. Попробуйте позже';
  }
  return 'Неверный логин или пароль';
}

function getAuthByPhoneErrorMessage({
  signInError,
  tooManyRequest,
  temporaryBanned,
  lastAttempt,
}: {
  signInError: boolean;
  tooManyRequest: boolean;
  temporaryBanned: boolean;
  lastAttempt: boolean;
}) {
  if (!signInError) {
    return '';
  }
  if (temporaryBanned) {
    return '>Вы исчерпали все попытки. Попробуйте позже';
  }
  if (lastAttempt) {
    return 'Пользователь с&nbsp;данным номером не зарегистрирован. Вы исчерпали все попытки. Попробуйте позже';
  }
  if (tooManyRequest) {
    return 'Превышен лимит запросов';
  }
  return 'Пользователь с&nbsp;данным номером не зарегистрирован';
}
