import { Getter, Mutation, Commit, Dispatch } from 'vuex';

export interface IStore<State, IGetters> {
  state: State;
  getters: IGetters;
  commit: Commit;
  dispatch: Dispatch;
}

/** Создает один простой геттер */
function createSimpleGetter<State>(name: keyof State): TSimpleGetter<State> {
  return {
    [name]: (state: State) => state[name],
  };
}

/** Создает несколько простых геттеров */
export function createSimpleGetters<State>(names: (keyof State)[] = []): TSimpleGetter<State> {
  return names.reduce(
    (result, getterName) => ({
      ...result,
      ...createSimpleGetter<State>(getterName),
    }),
    {},
  );
}

/** Создать одну простую мутацию */
function createSimpleMutation<State>(name: keyof State): TSimpleMutation<State> {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [name]: (state: State, value: any) => {
      state[name] = value;
    },
  };
}

/** Создать несколько простых мутаций */
export function createSimpleMutations<State>(names: (keyof State)[] = []): TSimpleMutation<State> {
  return names.reduce(
    (result, mutationName) => ({
      ...result,
      ...createSimpleMutation<State>(mutationName),
    }),
    {},
  );
}

type TSimpleGetter<State> = { [name: string]: Getter<State, unknown> };
type TSimpleMutation<State> = { [name: string]: Mutation<State> };
