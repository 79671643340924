
import { defineComponent, PropType } from 'vue';
import AList, { ListSize } from 'ant-design-vue/es/list';

export default defineComponent({
  components: {
    AList,
  },

  props: {
    /**
     * Размер списка
     */
    size: {
      type: String as PropType<ListSize>,
      default: 'default',
    },
    /**
     * Отображать границы
     */
    bordered: {
      type: Boolean,
      default: false,
    },
    /**
     * Отображать границы
     */
    dataSource: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {
      //
    };
  },
});
