
import { defineComponent, computed } from 'vue';
import ADatePicker from 'ant-design-vue/es/date-picker';
import localeRu from 'ant-design-vue/es/date-picker/locale/ru_RU';
import dayjs, { Dayjs } from 'dayjs';

localeRu.lang.locale = 'ru';

export default defineComponent({
  components: {
    ADatePicker,
  },
  props: {
    /**
     * Выбранная дата
     */
    modelValue: {
      type: [String, Date],
      default: '',
    },
    /**
     * Отключить элемент
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Заполнитель
     */
    placeholder: {
      type: [String],
      default: '',
    },
    /**
     * Показывать часы и минуты
     */
    showTime: {
      type: Boolean,
      default: false,
    },
    /**
     * Разрешить очищать ввод
     */
    allowClear: {
      type: Boolean,
      default: false,
    },
    /**
     * Формат даты
     */
    format: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    return {
      localeRu,
      defaultValue: computed(() => (props.modelValue ? dayjs(props.modelValue) : '')),
      update(newValue: string | Dayjs) {
        emit('update:modelValue', newValue ? dayjs(newValue).toDate() : newValue);
      },
    };
  },
});
