
import { ref, computed, defineComponent, PropType, onMounted, onUnmounted } from 'vue';
import ATable, { ColumnGroupType, ColumnType } from 'ant-design-vue/es/table';
import ruRU from 'ant-design-vue/es/locale/ru_RU';
import AConfigProvider from 'ant-design-vue/es/config-provider';
import { AlignType, GetComponentProps } from 'ant-design-vue/es/vc-table/interface';
import { DefaultRecordType } from 'ant-design-vue/es/vc-table/interface';
import { useRouter } from 'vue-router';

const SCREEN_LAPTOP_BREACKPOINT = 1024;

export default defineComponent({
  inheritAttrs: false,
  components: {
    ATable,
    ATableSummaryRow: ATable.Summary.Row,
    ATableSummaryCell: ATable.Summary.Cell,
    AConfigProvider,
  },
  props: {
    columns: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<(ColumnGroupType<any> | ColumnType<any>)[]>,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    summary: {
      type: Array as PropType<TableItem[]>,
      default: () => [],
    },
    rowLink: {
      type: Function,
    },
    customRow: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<GetComponentProps<any>>,
    },
    showTotal: {
      type: Function as PropType<((total: number, range: [number, number]) => string) | undefined>,
      required: false,
    },
  },
  setup(props) {
    const rowSelectable = computed(() => props.customRow || props.rowLink);

    const customRowProxy = computed(() => {
      if (props.rowLink) {
        const router = useRouter();
        return (record: DefaultRecordType) => {
          return {
            onClick: () => {
              router.push(props.rowLink?.(record));
            },
            onMouseup: (event: MouseEvent) => {
              if (event.button === 1) {
                const url = router.resolve(props.rowLink?.(record));
                window.open(url.href, '_blank');
              }
            },
            onMousedown: (event: MouseEvent) => {
              if (event.button === 1) {
                event.preventDefault();
                return false;
              }
              return true;
            },
          };
        };
      }

      return props.customRow || (() => ({}));
    });

    const windowWidth = ref(window.innerWidth);
    const updateWindowWidth = () => (windowWidth.value = window.innerWidth);
    onMounted(() => window.addEventListener('resize', updateWindowWidth));
    onUnmounted(() => window.removeEventListener('resize', updateWindowWidth));

    return {
      localeRu: ruRU,
      rowSelectable,
      customRowProxy,
      isHorizontalScrollActive: computed(() => (windowWidth.value <= SCREEN_LAPTOP_BREACKPOINT ? true : undefined)),
    };
  },
});

export type TableItem = {
  title: string | number;
  dataIndex: string | number;
  align?: AlignType | undefined;
};
