/* eslint-disable @typescript-eslint/no-explicit-any */

type OnStorageChange = (value: any, oldValue: any, url?: string) => void;

type Listeners = { [key: string]: Array<OnStorageChange> };

class LocalStorage {
  ls: Storage | null;
  listeners: Listeners = {};
  listening = false;

  constructor() {
    this.ls = window.localStorage;
  }

  get(key: string): any {
    const value: string | null | undefined = this.ls?.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  set(key: string, value?: any, notify = false) {
    try {
      this.ls?.setItem(key, JSON.stringify(value));
      if (notify) {
        this.notifyListeners(key, value);
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  remove(key: string, notify = false): void {
    this.ls?.removeItem(key);
    if (notify) {
      this.notifyListeners(key, null);
    }
  }

  clear(): void {
    this.ls?.clear();
  }

  on(key: string, fn: OnStorageChange) {
    if (this.listeners[key]) {
      this.listeners[key].push(fn);
    } else {
      this.listeners[key] = [fn];
    }
    if (!this.listening) {
      this.listen();
    }
  }

  off(key: string, fn: OnStorageChange) {
    const ns = this.listeners[key];
    if (ns.length > 1) {
      ns.splice(ns.indexOf(fn), 1);
    } else {
      this.listeners[key] = [];
    }
  }

  onChange(event: StorageEvent) {
    if (!event) {
      // noinspection JSDeprecatedSymbols
      event = window.event as StorageEvent;
    }
    if (!event?.key) {
      return;
    }
    // console.log(event, this.listeners);
    this.notifyListeners(
      event.key,
      event.newValue ? JSON.parse(event.newValue) : null,
      event.oldValue ? JSON.parse(event.oldValue) : null,
      event.url || (event as any)?.uri || event.key,
    );
  }

  notifyListeners(key: string, value: string | null, oldValue?: string | null, url?: string) {
    (this.listeners[key] || []).forEach((listener: OnStorageChange) => {
      listener(value, oldValue, url);
    });
  }

  listen() {
    if (window.addEventListener) {
      window.addEventListener('storage', (e) => this.onChange(e));
    }
    this.listening = true;
  }
}

const ls = new LocalStorage();

export default ls;
