import { Router } from 'vue-router';

export function hasWelcomeFlag(router: Router) {
  return Boolean(router.currentRoute.value.query.welcome);
}
export function setWelcomeFlag(router: Router) {
  router.push({
    name: 'home',
    query: {
      ...router.currentRoute.value.query,
      welcome: 'true',
    },
  });
}
export function removeWelcomeFlag(router: Router) {
  router.push({
    name: router.currentRoute.value.name as string,
    query: {
      ...router.currentRoute.value.query,
      welcome: undefined,
    },
  });
}
