import { computed, defineComponent, h, VNode } from 'vue';
import './VSvgIcon.scss';

export default defineComponent({
  name: 'VSvgIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    title: String,
  },
  setup(props) {
    return {
      iconPath: computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        let icon = require(`@/assets/icons/${props.name}.svg`);
        if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
          icon = icon.default;
        }
        return icon.url;
      }),
    };
  },
  render(): VNode {
    return h(
      'svg',
      {
        class: ['v-svg-icon', this.name.replace('/', '-').trim()],
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        ...(this.title ? [h('title', this.title)] : []),
        h('use', {
          'xlink:href': this.iconPath,
          'xmlns:xlink': 'http://www.w3.org/1999/xlink',
        }),
      ],
    );
  },
});
