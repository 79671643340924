
import { defineComponent, PropType } from 'vue';
import ASkeleton from 'ant-design-vue/es/skeleton';

export default defineComponent({
  components: {
    ASkeleton,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    paragraph: {
      type: Object as PropType<{ rows: number }>,
      default: () => ({}),
    },
  },
});
