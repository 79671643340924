
import { defineComponent, PropType, computed } from 'vue';
import { getDefaultTimeRanges } from '@/helpers/time';

export default defineComponent({
  emits: ['update:modelValue'],

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: Array as PropType<Date[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const [dateRangeToday, dateRangeYesterday, dateRangeMonth] = getDefaultTimeRanges();

    const dateRangeProxy = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      dateRangeProxy,
      dateRangeToday,
      dateRangeYesterday,
      dateRangeMonth,
    };
  },
});
