
import { defineComponent } from 'vue';
import ATag from 'ant-design-vue/es/tag';

export default defineComponent({
  components: {
    ATag,
  },
  props: {
    color: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
});
