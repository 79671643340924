import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02198940"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_UiButton, {
        key: item.value,
        class: _normalizeClass(["tabs-item mr-4", { 'tabs-item-selected': _ctx.selected === item.value, 'tabs-item-disabled': _ctx.disabled }]),
        disabled: _ctx.disabled,
        type: "text",
        shape: "round",
        onClick: ($event: any) => (_ctx.select(item))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.text), 1)
        ]),
        _: 2
      }, 1032, ["class", "disabled", "onClick"]))
    }), 128))
  ]))
}