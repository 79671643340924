import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiMenu = _resolveComponent("UiMenu")!
  const _component_VMenu = _resolveComponent("VMenu")!
  const _component_UiText = _resolveComponent("UiText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VMenu, {
      left: "",
      label: _ctx.selectedCompanyName,
      class: "company",
      expandToTop: _ctx.expandToTop
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UiMenu, {
          class: "menu",
          selectedKeys: _ctx.selectedCompany,
          items: _ctx.companySelectOptions,
          mode: "inline",
          onSelect: _ctx.selectCompany
        }, null, 8, ["selectedKeys", "items", "onSelect"])
      ]),
      _: 1
    }, 8, ["label", "expandToTop"]),
    (_ctx.isSelectedCompanyNumberVisible && _ctx.selectedCompanyNumber)
      ? (_openBlock(), _createBlock(_component_UiText, {
          key: 0,
          class: "contract-number",
          type: "secondary"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.selectedCompanyNumber), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}