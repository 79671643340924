
import { defineComponent } from 'vue';
import ATextArea from 'ant-design-vue/es/input/TextArea';

export default defineComponent({
  emits: ['update:value'],

  components: {
    ATextArea,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
  },
});
