
import { defineComponent, PropType } from 'vue';
import ATree, { DataNode } from 'ant-design-vue/es/tree';

export default defineComponent({
  components: {
    ATree,
  },
  props: {
    treeData: {
      type: Array as PropType<DataNode[]>,
      required: true,
    },
  },
});
