
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    // заголовок секции
    title: {
      type: String,
      default: '',
    },
    // заголовок секции
    dense: {
      type: Boolean,
      default: false,
    },
    // отступ после заголовка, true = mb-0
    denseTitle: {
      type: Boolean,
      default: false,
    },
    // цвет заголовка, true = grey
    mutedTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      //
    };
  },
});
