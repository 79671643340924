
import { computed, defineComponent, ref, watch } from 'vue';
import { mapGetters, mapState, useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { ROUTE_PARAMS_TYPE } from '@/router';
import AuthView from '@/views/auth/index.vue';
import TopPanel from '@/components/TopPanel.vue';
import TopPanelMobile from '@/components/TopPanelMobile.vue';
import SideMenu from '@/components/SideMenu.vue';
import SideMenuMobile from './components/SideMenuMobile.vue';

export default defineComponent({
  components: {
    AuthView,
    TopPanel,
    TopPanelMobile,
    SideMenu,
    SideMenuMobile,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isAuthorized = computed(() => store.getters['auth/isAuthorized']);

    const id = computed(() => {
      const { id } = router.currentRoute.value.params;
      return Array.isArray(id) ? id[0] : id;
    });
    const type = computed(() => {
      const { type } = route.params;
      return Array.isArray(type) ? type[0] : type;
    });

    const loadCabinet = () => {
      return store.dispatch('cabinet/loadCabinet', {
        contractId: type.value === ROUTE_PARAMS_TYPE.station ? id.value : undefined,
        companyId: type.value === ROUTE_PARAMS_TYPE.company ? id.value : undefined,
        departmentId: type.value === ROUTE_PARAMS_TYPE.department ? id.value : undefined,
      });
    };

    watch(
      () => isAuthorized.value,
      async (isAuth) => {
        if (isAuth) {
          await loadCabinet();
        }
      },
      { immediate: true },
    );

    const isMobileMenuVisible = ref(false);
    const toggleMobileMenu = () => (isMobileMenuVisible.value = !isMobileMenuVisible.value);

    return {
      id,
      type,
      isAuthorized,
      isMobileMenuVisible,
      toggleMobileMenu,
    };
  },

  computed: {
    ...mapState('cabinet', ['errorMessage']),
    ...mapGetters('cabinet', ['isLoaded']),
  },
});
