import { defineComponent, h, VNode, Transition } from 'vue';
import { IStepper } from './VStepper';

export default defineComponent({
  props: {
    step: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  created() {
    const parent = this.$parent as unknown;
    (parent as IStepper)?.registerStep(String(this.step || ''), this);
  },
  render(): VNode | null {
    return h(Transition, { name: 'stepper' }, () => [
      this.isActive
        ? h(
            'div',
            {
              class: `v-stepper-content ${this.step}`,
              style: this.isActive ? {} : { display: 'none' },
            },
            this.$slots.default?.(),
          )
        : null,
    ]);
  },
});
