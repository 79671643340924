import { ActionContext } from 'vuex';
import { RootState, CabinetState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  mutation saveCompany ($companyId: ID!, $data: CompanyData!) {
    saveCompany (companyId: $companyId, data: $data) {
        id name brand companyType type role b2b
        officialAddress postAddress inn kpp ogrn
        contact director phone email
        buh { name phone email }
        tech { name phone }
        status
        payments { balance needMoney }
        drivers { hasAny }
        contract { 
          id
          bank
          bik
          account
          correspondence
          number
          discount
          agentCommission
        }
    }
  }
`;

export default (
  {
    commit,
    rootState: {
      cabinet: { company },
      auth: { token },
    },
  }: ActionContext<CabinetState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
) => {
  commit('isRequestInProgress', true);
  return api
    .graphql({
      query,
      variables: {
        companyId: company?.id,
        data,
      },
      token,
    })
    .then((result) => {
      const company = result.saveCompany;
      if (company) {
        commit('setCompany', { ...company, hasDrivers: !!company.drivers?.hasAny });
      }
      return company;
    })
    .finally(() => {
      commit('isRequestInProgress', false);
    });
};
