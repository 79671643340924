import { defineComponent, h, reactive, TransitionGroup, VNode } from 'vue';
import './VApp.scss';
import app from '@/main';
import { VPanel } from '@/components/Cards';
import { VSpacer } from '@/components/Layout/index';
import { VBtn } from '@/components/Button';
import { VSvgIcon } from '@/components/Images';

let NOTIFICATIONS_SEQUENCE = 0;

export enum NotificationType {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  ALERT = 'alert',
}

type Notification = {
  id: number;
  title?: string;
  message: string;
  type: NotificationType;
};

export default defineComponent({
  name: 'VApp',
  setup() {
    const notifications = reactive<Notification[]>([]);
    const removeNotification = (removeId: number) => {
      const index = notifications.findIndex(({ id }) => id === removeId);
      if (index >= 0) {
        notifications.splice(index, 1);
      }
    };
    app.config.globalProperties.$notify = (
      message: string,
      title?: string,
      type: NotificationType = NotificationType.INFO,
      timeout = 3000,
    ) => {
      const id = NOTIFICATIONS_SEQUENCE++;
      notifications.push({ id, title, message, type });
      setTimeout(() => removeNotification(id), timeout);
    };
    return {
      notifications,
      removeNotification,
    };
  },
  render(): VNode {
    return h('div', { class: 'v-app' }, [
      ...(this.$slots.drawer ? [h('aside', { class: 'v-app-drawer' }, this.$slots.drawer())] : []),
      h('div', { class: 'v-app-wrapper' }, [
        ...(this.$slots.header
          ? [h('header', { class: 'v-app-header d-flex align-center' }, this.$slots.header())]
          : []),
        h('main', { class: 'v-app-content' }, this.$slots.default?.()),
        ...(this.notifications.length > 0
          ? [
              h(TransitionGroup, { tag: 'div', name: 'notifications', class: 'v-app-notifications' }, () =>
                this.notifications.map((notification) =>
                  h(
                    VPanel,
                    { class: `message ${notification.type}`, key: notification.id, radius: 0 },
                    {
                      header: () =>
                        h('h3', { class: 'd-flex align-center' }, [
                          notification.title,
                          h(VSpacer),
                          h(
                            VBtn,
                            { tile: true, small: true, onClick: () => this.removeNotification(notification.id) },
                            () => h(VSvgIcon, { name: 'close' }),
                          ),
                        ]),
                      default: () => h('div', { class: 'mt-2' }, notification.message),
                    },
                  ),
                ),
              ),
            ]
          : []),
      ]),
    ]);
  },
});
