import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AAlert = _resolveComponent("AAlert")!

  return (_openBlock(), _createBlock(_component_AAlert, {
    type: _ctx.type,
    message: _ctx.message,
    closable: _ctx.closable
  }, {
    icon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon")
    ]),
    description: _withCtx(() => [
      _renderSlot(_ctx.$slots, "description")
    ]),
    _: 3
  }, 8, ["type", "message", "closable"]))
}