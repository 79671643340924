
import { defineComponent } from 'vue';
import ASpin from 'ant-design-vue/es/spin';

export default defineComponent({
  components: {
    ASpin,
  },
  props: {
    spinning: {
      type: Boolean,
      default: true,
    },
  },
});
