import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6597c67d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiDateRangePicker = _resolveComponent("UiDateRangePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiDateRangePicker, {
      modelValue: _ctx.dateRangeProxy,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRangeProxy) = $event)),
      class: "d-flex align-center filter-field-date",
      placeholder: ['От', 'До'],
      readonly: true,
      disabled: _ctx.loading
    }, null, 8, ["modelValue", "disabled"]),
    _createElementVNode("div", null, [
      _createElementVNode("span", {
        class: "filter-date-tag",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dateRangeProxy = _ctx.dateRangeToday))
      }, "Сегодня"),
      _createElementVNode("span", {
        class: "filter-date-tag",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dateRangeProxy = _ctx.dateRangeYesterday))
      }, "Вчера"),
      _createElementVNode("span", {
        class: "filter-date-tag",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dateRangeProxy = _ctx.dateRangeMonth))
      }, "Месяц")
    ])
  ]))
}