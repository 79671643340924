/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, h } from 'vue';
import './VTable.scss';

type Column = {
  label: string;
  prop: string;
  headerParams: any;
  bodyParams: any;
};

export default defineComponent({
  name: 'VTable',
  props: {
    options: {
      type: Object,
      required: true,
    },
    items: Array,
  },
  computed: {
    cols(): Column[] {
      return this.options?.cols?.map((col: any) => {
        return typeof col === 'string'
          ? {
              label: col,
              prop: col,
              // width: null,
            }
          : {
              label: col.label,
              prop: col.prop,
              // width: col.prop || null,
            };
      });
    },
  },
  render() {
    if (!this.cols.length) {
      return null;
    }
    return h('table', { class: 'v-table' }, [
      h('thead', [
        h(
          'tr',
          this.cols.map((col: Column) => h('th', { ...col.headerParams }, col.label)),
        ),
      ]),
      h(
        'tbody',
        this.items?.map?.((item: any) => {
          return (
            this.$slots.item?.({ item }) ??
            h(
              'tr',
              this.cols.map((col: Column) => h('td', { ...col.bodyParams }, item[col.prop])),
            )
          );
        }),
      ),
      ...(this.$slots.footer ? [h('tfoot', this.$slots.footer())] : []),
    ]);
  },
});
