import { api } from '@/store';
import { getAuthToken } from '@/store/auth';
import { TypedDocumentString } from '@/gql/graphql';

export default function <TResult, TVariables extends Record<string, unknown>>(
  document: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables,
): Promise<TResult> {
  return api.graphql({
    query: q(document.toString()),
    variables: variables ? v(variables) : undefined,
    token: getAuthToken(),
  });
}

// чистка запроса (удаление лишних отступов\пробелов)
export const q = (query: string) => {
  return query
    .replace(/[\n\t ]+/g, ' ') // заменяет переносы на новые строки, табуляцию и пробелы на один пробел
    .replace(/([{}():,])\s+/g, '$1') // удаляет пробелы после аргументов запроса
    .replace(/\s+([{}():,])/g, '$1') // удаляет пробелы до аргументов запроса
    .replace(/\s\s+/g, ' ') // заменяет два и более пробела на один
    .trim(); // удаляет отступы в начале и конце запроса
};

// чистка переменных (удаление ключей с falsy-значениями)
const v = (variables: Record<string, unknown>) => {
  return Object.fromEntries(
    Object.entries(variables).filter(([_, value]) => {
      // некоторые роуты могут обязательно требовать false/0
      return value || value === false || value === 0;
    }),
  );
};
