import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBreadcrumbs = _resolveComponent("UiBreadcrumbs")!
  const _component_UiTitle = _resolveComponent("UiTitle")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["v-app-content--header", _ctx.breadcrumbs.length > 0 ? ['v-app-content--header-small', 'small-padding'] : []])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (_ctx.breadcrumbs)
        ? (_openBlock(), _createBlock(_component_UiBreadcrumbs, {
            key: 0,
            class: "breadcrumbs",
            items: _ctx.breadcrumbs
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createVNode(_component_UiTitle, { class: "header" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      })
    ], true)
  ], 2))
}