
import { defineComponent, ref, watch } from 'vue';
import { stationsGetMapStation } from '@/services/StationsService';

export default defineComponent({
  props: {
    stationId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const isRequestInProgress = ref(false);
    const errorMessage = ref('');
    const body = ref(null);

    const name = ref('');
    const address = ref('');
    const prices = ref<{ price: number; fuel: { code: string } }[]>([]);

    watch(
      () => props.stationId,
      async (id) => {
        if (id) {
          isRequestInProgress.value = true;
          errorMessage.value = '';

          try {
            const result = await stationsGetMapStation({ id });
            if (result.station) {
              const station = result.station;
              name.value = station.name;
              address.value = station.address ?? '';
              prices.value = station.prices ?? [];
            } else {
              name.value = 'Станция не найдена';
              address.value = '-';
              prices.value = [];
            }
          } catch (error) {
            errorMessage.value = `Ошибка получения станции`;
          } finally {
            isRequestInProgress.value = false;
          }
        }
      },
    );

    return {
      isRequestInProgress,
      body,
      name,
      address,
      prices,
    };
  },
});
