import router from './index';

export function addRouterQuery(flag: string, value = 'true') {
  router.push({
    name: router.currentRoute.value.name as string,
    query: {
      ...router.currentRoute.value.query,
      [flag]: value,
    },
  });
}

export function removeRouterQuery(flag: string) {
  router.push({
    name: router.currentRoute.value.name as string,
    query: {
      ...router.currentRoute.value.query,
      [flag]: undefined,
    },
  });
}

export function hasRouterQuery(flag: string) {
  return Boolean(router.currentRoute.value.query[flag]);
}
