import { defineComponent, h, VNode } from 'vue';
import './VStepper.scss';
import { ComponentCustomProperties } from '@vue/runtime-core';

export interface IStepperContent {
  isActive: boolean;
}

type Step = {
  index?: number;
  step: string;
  content: IStepperContent;
};

interface RegisterStep {
  (step: string, content: IStepperContent): void;
}

export interface IStepper extends ComponentCustomProperties {
  step: string;
  registerStep: RegisterStep;
}

export default defineComponent({
  props: {
    modelValue: [String, Number],
  },

  data() {
    return {
      steps: [] as Step[],
      step: '',
      reverse: false,
    };
  },

  created() {
    this.step = String(this.modelValue || '');
  },

  watch: {
    modelValue(val: string | number) {
      val = String(val);
      if (val === this.step) {
        return;
      }

      const newStep = this.getStep(val);
      if (!newStep) {
        return;
      }

      const currentStep = this.getStep(String(this.step));
      if (currentStep) {
        this.reverse = (newStep?.index || 0) < (currentStep?.index || 0);
        this.$nextTick(() => {
          currentStep.content.isActive = false;
        });
      } else {
        this.reverse = false;
      }
      this.step = newStep.step;
      newStep.content.isActive = true;
    },
  },

  render(): VNode {
    return h(
      'div',
      {
        class: {
          'v-stepper': true,
          'v-stepper--reverse': this.reverse,
        },
      },
      [h('div', { class: 'v-stepper--inner' }, this.$slots.default?.())],
    );
  },

  methods: {
    registerStep(step: string, content: IStepperContent) {
      this.steps.push({ step, content });
      if (String(this.modelValue || '') === step) {
        content.isActive = true;
      }
    },
    getStep(stepId: string): Step | null {
      const index = this.steps.findIndex(({ step }) => step === stepId);
      if (index === -1) {
        return null;
      }
      return { index, ...this.steps[index] };
    },
  },
});
