import { defineComponent, h, VNode } from 'vue';

export default defineComponent({
  name: 'VCol',
  props: {
    cols: { type: [String, Number], default: 12 },
    xs: { type: [String, Number] },
    sm: { type: [String, Number] },
    md: { type: [String, Number] },
    lg: { type: [String, Number] },
    xl: { type: [String, Number] },
  },
  render(): VNode {
    const { cols, xs, sm, md, lg, xl } = this;
    return h(
      'div',
      {
        class: {
          ['col']: true,
          [`col-${cols}`]: true,
          [`col-xs-${xs}`]: xs,
          [`col-sm-${sm}`]: sm,
          [`col-md-${md}`]: md,
          [`col-lg-${lg}`]: lg,
          [`col-xl-${xl}`]: xl,
        },
      },
      this.$slots.default?.(),
    );
  },
});
