
import { defineComponent } from 'vue';
import AModal from 'ant-design-vue/es/modal';

export default defineComponent({
  components: {
    AModal,
  },
  props: {
    /**
     * Показать модальное окно
     */
    open: {
      type: Boolean,
      default: true,
    },
    /**
     * Возможность закрыть окно с помощью крестика в правом верхнем углу
     */
    closable: {
      type: Boolean,
      default: false,
    },
    /**
     * Возможность закрыть окно с помощью клика вне окна
     */
    maskClosable: {
      type: Boolean,
      default: false,
    },
    /**
     * Возможность закрыть окно с помощью клавиатуры (нажав esc)
     */
    keyboard: {
      type: Boolean,
      default: false,
    },
    /**
     * Отображение модального окна по центру экрана
     */
    centered: {
      type: Boolean,
      default: true,
    },
    /**
     * Нижний колонтитул
     */
    footer: {
      type: Boolean,
      default: false,
    },
    /**
     * Ширина окна
     */
    width: {
      type: String,
      required: false,
    },
  },
});
