import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AAutoComplete = _resolveComponent("AAutoComplete")!
  const _component_UiText = _resolveComponent("UiText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'ant-form-item-has-error': _ctx.error }, "input-wrapper"])
  }, [
    _createVNode(_component_AAutoComplete, {
      class: "input",
      options: _ctx.options,
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      value: _ctx.inputLocalValue,
      allowClear: _ctx.allowClear,
      onInput: _ctx.handleInput,
      onSelect: _ctx.select
    }, _createSlots({
      prefix: _withCtx(() => [
        _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
      ]),
      suffix: _withCtx(() => [
        _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
      ]),
      _: 2
    }, [
      ('option' in _ctx.$slots)
        ? {
            name: "option",
            fn: _withCtx((data) => [
              _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps(data)), undefined, true)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["options", "type", "placeholder", "disabled", "value", "allowClear", "onInput", "onSelect"]),
    (_ctx.error && _ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_UiText, {
          key: 0,
          class: "input-error-message",
          type: "danger"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}