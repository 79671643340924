import { ActionContext } from 'vuex';
import { RootState, CabinetState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  mutation createPayment ($contractId: ID!, $providerId: ID!, $cardId: ID, $amount: Float!) {
    createPayment (contractId: $contractId, providerId: $providerId, cardId: $cardId, amount: $amount) {
      id
      ... on InvoicePaymentResponse {
        url number
      }
      ... on OnlinePaymentResponse {
        url auto
      }
    }
  }
`;

export default (
  {
    rootState: {
      cabinet: { company },
      auth: { token },
    },
  }: ActionContext<CabinetState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { providerId, cardId, amount, contractId }: any,
) =>
  api
    .graphql({
      query,
      variables: {
        contractId: contractId ?? company?.contract?.id,
        providerId,
        cardId,
        amount: Number(amount),
      },
      token,
    })
    .then((result) => result.createPayment);
