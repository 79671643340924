import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_APopover = _resolveComponent("APopover")!

  return (_openBlock(), _createBlock(_component_APopover, {
    title: _ctx.title,
    trigger: _ctx.trigger
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["title", "trigger"]))
}