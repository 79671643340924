import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, {
    class: "button",
    type: _ctx.type,
    size: _ctx.size,
    loading: _ctx.loading,
    disabled: _ctx.disabled,
    danger: _ctx.danger
  }, {
    icon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["type", "size", "loading", "disabled", "danger"]))
}