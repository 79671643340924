import { ActionContext } from 'vuex';
import { CabinetState, RootState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  query getPartners ($companyId: ID!) {
    cabinet {
      company(id: $companyId) {
        partners { id name driversCount registeredDriversCount }
      }
    }
  }
`;

export default (
  {
    rootState: {
      auth: { token },
    },
  }: ActionContext<CabinetState, RootState>,
  companyId: string,
) =>
  api
    .graphql({
      query,
      variables: { companyId, filter: {} },
      token,
    })
    .then((result) => result.cabinet?.company?.partners);
