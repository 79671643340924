import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AModal = _resolveComponent("AModal")!

  return (_openBlock(), _createBlock(_component_AModal, {
    open: _ctx.open,
    closable: _ctx.closable,
    centered: _ctx.centered,
    footer: _ctx.footer,
    width: _ctx.width,
    maskClosable: _ctx.maskClosable,
    keyboard: _ctx.keyboard,
    onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["open", "closable", "centered", "footer", "width", "maskClosable", "keyboard"]))
}