import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-app-content--header d-flex" }
const _hoisted_2 = { style: {"text-transform":"lowercase"} }
const _hoisted_3 = { class: "pd-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VSpacer = _resolveComponent("VSpacer")!
  const _component_VBtn = _resolveComponent("VBtn")!
  const _component_UiTable = _resolveComponent("UiTable")!
  const _component_VPanel = _resolveComponent("VPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("h1", null, [
        _createTextVNode(" Движение по балансу за "),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ]),
      _createVNode(_component_VSpacer),
      _createVNode(_component_VBtn, {
        color: "primary",
        onClick: _ctx.download
      }, {
        default: _withCtx(() => [
          _createTextVNode("Скачать")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_VPanel, null, {
        default: _withCtx(() => [
          _createVNode(_component_UiTable, {
            columns: _ctx.tableColumns,
            items: _ctx.reportMonthly,
            loading: _ctx.isRequestInProgress,
            summary: _ctx.reportMonthlySummary,
            pagination: false
          }, null, 8, ["columns", "items", "loading", "summary"])
        ]),
        _: 1
      })
    ])
  ], 64))
}