import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AMenu = _resolveComponent("AMenu")!
  const _component_ADropdown = _resolveComponent("ADropdown")!

  return (_openBlock(), _createBlock(_component_ADropdown, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
    overlay: _withCtx(() => [
      _createVNode(_component_AMenu, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "overlay")
        ]),
        _: 3
      })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16))
}