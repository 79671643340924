import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Company, Cabinet, UsersRoles, CompanyUserAccess, Fuel } from '@/gql/graphql';
import { ROUTE_PARAMS_TYPE } from '@/router';
import { FUELUP_COMPANY_ID } from '@/config/constants';

export default function useCabinetStore() {
  const store = useStore();

  const user = computed<Cabinet>(() => store.getters['cabinet/user']);
  const company = computed<Company>(() => store.getters['cabinet/company']);
  const breadcrumbs = computed(() => store.getters['cabinet/breadcrumbs']);
  const needToFillData = computed(() => store.getters['cabinet/needToFeelData']);

  const companyId = computed(() => company.value.id);

  /**
   * Тип компании (internal, azs, tk, ...)
   */
  const companyType = computed(() => company.value.type);
  const companies = computed<Company[]>(() => user.value.companies);

  /**
   * Роль пользователя (admin, manager, support, ...)
   */
  const userRole = computed<UsersRoles>(() => company.value?.role as UsersRoles);
  const userAccess = computed<CompanyUserAccess[]>(() => company.value.userAccess ?? []);
  const isFuelUpAccess = computed(() => !!companies.value.find((company) => company.id === FUELUP_COMPANY_ID));

  const fuel = computed<Fuel[]>(() => store.getters['cabinet/fuel']);
  const fuelGroups = computed(() => store.getters['cabinet/fuelGroups']);

  const route = useRoute();
  const isDepartment = computed(() => route.params.type === ROUTE_PARAMS_TYPE.department);

  const isBackToAdminAvailable = computed(
    () => company.value && company.value?.id !== FUELUP_COMPANY_ID && isFuelUpAccess.value,
  );

  return {
    store,
    user,
    userRole,
    userAccess,
    company,
    companyId,
    companyType,
    companies,
    breadcrumbs,
    isFuelUpAccess,
    needToFillData,
    fuel,
    fuelGroups,
    isDepartment,
    isBackToAdminAvailable,
  };
}
