
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Tabs>,
      default: () => [],
    },
    selected: {
      type: [String, Number],
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    return {
      select(item: Tab) {
        emit('change', item);
      },
    };
  },
});

export type Tab = {
  text: string;
  value: string;
};

export type Tabs = Tab[];
