import { Module } from 'vuex';
import { createSimpleMutations } from '@/helpers/vuex';
import { RootState } from '@/store';
import { CompanyUserAccess, Drivers } from '@/gql/graphql';
import * as actions from './actions';

export type User = {
  id?: string;
  name: string;
  phone: string;
};

export interface UsersState {
  isRequestInProgress: boolean;
  errorMessage: string;
  drivers: Drivers;
  userAccess: CompanyUserAccess[];
}

const users: Module<UsersState, RootState> = {
  namespaced: true,
  state: (): UsersState => ({
    userAccess: [] as CompanyUserAccess[],
    drivers: {} as Drivers,
    isRequestInProgress: false,
    errorMessage: '',
  }),
  mutations: {
    ...createSimpleMutations<UsersState>(['drivers', 'userAccess', 'isRequestInProgress', 'errorMessage']),
  },
  getters: {
    driversItems: ({ drivers }) => drivers?.items || [],
    userAccessItems: ({ userAccess }) => userAccess || [],
  },

  actions,
};

export default users;

export type DriverCar = { id: string; number: string };

export type DriverLimits = {
  blocked: boolean;
  fuels: string[];
  fuelsGroups: string[];
  isQuantity: boolean;
  minLeft: number;
  day: null | number;
  month: null | number;
  week: null | number;
  regions: string[];
  limitsType: string;
};

export type DriverPartnerCar = {
  partner: DriverPartner;
  car: DriverCar;
  isActive: boolean;
  limits: DriverLimits;
};

export type DriverPartner = {
  id: string;
  name: string;
};
