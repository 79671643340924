import { App } from 'vue';
import * as layout from './Layout';
import * as common from './Common';
import * as cards from './Cards';
import * as images from './Images';
import * as steps from './Steps';
import * as button from './Button';
import * as forms from './Forms';
import * as dialogs from './Dialogs';
import * as menu from './Menu';
import * as tables from './Tables';
import * as navs from './Navigation';
import * as labels from './Labels';
import * as uikit from './uikit';

export default {
  install: (app: App) => {
    Object.entries({
      ...layout,
      ...common,
      ...cards,
      ...images,
      ...steps,
      ...button,
      ...forms,
      ...dialogs,
      ...menu,
      ...tables,
      ...navs,
      ...labels,
      ...uikit,
    }).map(([id, component]) => {
      app.component(id, component);
    });
  },
};
