import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34cdec79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "body" }
const _hoisted_2 = { class: "mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "name", {}, () => [
      _createElementVNode("strong", null, _toDisplayString(_ctx.name), 1)
    ], true),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.address), 1)
  ], 512))
}