import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ABreadcrumbItem = _resolveComponent("ABreadcrumbItem")!
  const _component_ABreadcrumb = _resolveComponent("ABreadcrumb")!

  return (_openBlock(), _createBlock(_component_ABreadcrumb, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.title + item.urlPath
        }, [
          (item.urlPath)
            ? (_openBlock(), _createBlock(_component_ABreadcrumbItem, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_RouterLink, {
                    to: { path: item.urlPath, query: item.query }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _: 2
              }, 1024))
            : (_openBlock(), _createBlock(_component_ABreadcrumbItem, { key: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1024))
        ], 64))
      }), 128))
    ]),
    _: 1
  }))
}