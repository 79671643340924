import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ASwitch = _resolveComponent("ASwitch")!

  return (_openBlock(), _createBlock(_component_ASwitch, {
    checked: _ctx.modelValue,
    disabled: _ctx.disabled,
    loading: _ctx.loading,
    onChange: _ctx.change
  }, null, 8, ["checked", "disabled", "loading", "onChange"]))
}