import { ActionContext } from 'vuex';
import { AuthState, RootState, api, AuthResponse } from '@/store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async ({ commit }: ActionContext<AuthState, RootState>, { code, state }: any) =>
  api.post({ url: '/sbidReturn', data: { code, state } }).then((res: AuthResponse) => {
    if (res.token !== undefined) {
      commit('setToken', res.token);
      return {};
    }
    return res;
  });
