import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTitle = _resolveComponent("UiTitle")!
  const _component_VPanel = _resolveComponent("VPanel")!

  return (_openBlock(), _createBlock(_component_VPanel, { dense: _ctx.dense }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title", {}, () => [
        (_ctx.title)
          ? (_openBlock(), _createBlock(_component_UiTitle, {
              key: 0,
              level: 4,
              class: _normalizeClass({ 'mb-0': _ctx.denseTitle, muted: _ctx.mutedTitle })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true)
      ], true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["dense"]))
}