export default function toDecimal({
  value,
  digits = 2,
  suffix,
  defaultValue = '',
}: {
  value: string | number | undefined | null;
  digits?: number;
  suffix?: string;
  defaultValue?: string;
}) {
  if (!value) {
    return defaultValue;
  }
  const localeString = value.toLocaleString('ru', { maximumFractionDigits: digits, minimumFractionDigits: digits });
  return `${localeString} ${suffix || ''}`.trim();
}

export function toDecimal2(value: string | number | undefined | null, defaultValue = '-', digits = 2): string {
  if (value == null) {
    return defaultValue;
  }

  return value.toLocaleString('ru', { maximumFractionDigits: digits, minimumFractionDigits: digits }).trim();
}

export const roundPrecisely = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};
