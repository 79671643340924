
import { defineComponent, ref, PropType } from 'vue';
import AMenu, { MenuMode } from 'ant-design-vue/es/menu';

export default defineComponent({
  components: {
    AMenu,
    AMenuItem: AMenu.Item,
    ASubMenu: AMenu.SubMenu,
  },
  props: {
    items: {
      type: Array as PropType<ItemParent[]>,
      default: () => [],
    },
    mode: {
      type: String as PropType<MenuMode>,
      deafult: 'inline',
    },
    selectedKeys: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    return {
      selected: ref<string[]>(props.selectedKeys),
    };
  },
});

type ItemParent = Item & {
  children?: Item[];
};
type Item = {
  value: string | number;
  label: string | number | string[] | number[];
};
