import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba18c394"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cabinet-top-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanySelect = _resolveComponent("CompanySelect")!
  const _component_UiBreadcrumbs = _resolveComponent("UiBreadcrumbs")!
  const _component_VSpacer = _resolveComponent("VSpacer")!
  const _component_TopPanelBalance = _resolveComponent("TopPanelBalance")!
  const _component_TopPanelUserMenu = _resolveComponent("TopPanelUserMenu")!
  const _component_BackToAdminButton = _resolveComponent("BackToAdminButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.isSelectCompanyAvailable)
        ? (_openBlock(), _createBlock(_component_CompanySelect, {
            key: 0,
            selectedItemId: _ctx.selectedCompanyId,
            isSelectedCompanyNumberVisible: !_ctx.isDepartment,
            onSelect: _ctx.selectCompany
          }, null, 8, ["selectedItemId", "isSelectedCompanyNumberVisible", "onSelect"]))
        : _createCommentVNode("", true),
      (_ctx.isDepartment && _ctx.breadcrumbs.length)
        ? (_openBlock(), _createBlock(_component_UiBreadcrumbs, {
            key: 1,
            class: "breadcrubms",
            items: _ctx.breadcrumbs
          }, null, 8, ["items"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_VSpacer),
    (_ctx.isTK)
      ? (_openBlock(), _createBlock(_component_TopPanelBalance, {
          key: 0,
          class: "d-flex align-center mr-6"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_TopPanelUserMenu),
    (_ctx.isBackToAdminAvailable)
      ? (_openBlock(), _createBlock(_component_BackToAdminButton, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}