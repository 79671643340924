import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, mergeProps as _mergeProps, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b94eb1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ATableSummaryCell = _resolveComponent("ATableSummaryCell")!
  const _component_ATableSummaryRow = _resolveComponent("ATableSummaryRow")!
  const _component_ATable = _resolveComponent("ATable")!
  const _component_AConfigProvider = _resolveComponent("AConfigProvider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AConfigProvider, { locale: _ctx.localeRu }, {
      default: _withCtx(() => [
        _createVNode(_component_ATable, _mergeProps(_ctx.$attrs, {
          class: { 'row-selectable': _ctx.rowSelectable },
          customRow: _ctx.customRowProxy,
          dataSource: _ctx.items,
          columns: _ctx.columns,
          scroll: { x: _ctx.isHorizontalScrollActive },
          pagination: _ctx.tablePagination,
          onChange: _ctx.updatePagination
        }), _createSlots({ _: 2 }, [
          _renderList(_ctx.$slots, (_, name) => {
            return {
              name: name,
              fn: _withCtx((data) => [
                _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
              ])
            }
          }),
          (_ctx.summary.length)
            ? {
                name: "summary",
                fn: _withCtx(() => [
                  _createVNode(_component_ATableSummaryRow, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary, (item) => {
                        return (_openBlock(), _createBlock(_component_ATableSummaryCell, {
                          key: item.dataIndex,
                          class: "bg-gray",
                          align: item?.align
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_1, _toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["align"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1040, ["class", "customRow", "dataSource", "columns", "scroll", "pagination", "onChange"])
      ]),
      _: 3
    }, 8, ["locale"])
  ]))
}