import { ActionContext } from 'vuex';
import { AuthState, RootState, api, AuthResponse } from '@/store';
import recaptcha from '@/plugins/recaptcha';

type AuthParams = {
  email: string;
  password: string;
};

export default async ({ commit }: ActionContext<AuthState, RootState>, params: AuthParams) =>
  api.post({ url: '/signIn', data: { ...params, recaptcha: await recaptcha('signIn') } }).then((res: AuthResponse) => {
    commit('setToken', res.token);
    // return res;
  });
