import { defineComponent, h } from 'vue';
import VCard from '@/components/Cards/VCard';

export default defineComponent({
  name: 'VPanel',
  props: {
    radius: { type: [String, Number], default: '4' },
    dense: Boolean,
  },
  render() {
    return h(
      VCard,
      {
        outlined: true,
        class: `rounded-${this.radius}` + (this.dense ? '' : ' pd-4'),
      },
      {
        ...(this.$slots.header ? { header: () => this.$slots?.header?.() } : {}),
        default: () => this.$slots?.default?.(),
      },
    );
  },
});
