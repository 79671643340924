
import { defineComponent, PropType, computed } from 'vue';
import ACollapse from 'ant-design-vue/es/collapse';
import { ActiveKeyType } from 'ant-design-vue/es/collapse/commonProps';

export default defineComponent({
  components: {
    ACollapse,
  },
  props: {
    activeKey: {
      type: Object as PropType<ActiveKeyType | undefined>,
      required: true,
    },
    expandIconPosition: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
    },
    ghost: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const activeKeyProxy = computed({
      get: () => props.activeKey,
      set: (value) => {
        emit('update:activeKey', value);
      },
    });

    return {
      activeKeyProxy,
    };
  },
});
