import { Module } from 'vuex';
import { createSimpleMutations } from '@/helpers/vuex';
import { getDriver, saveDriver, removeDriver, UpdatedDriver } from '@/services/DriversService';
import { Driver, DriverLimits } from '@/gql/graphql';

export default {
  namespaced: true,

  state: (): State => ({
    driver: {} as Driver,
    isRequestInProgress: false,
    errorMessage: '',
  }),

  mutations: {
    ...createSimpleMutations<State>(['driver', 'isRequestInProgress', 'errorMessage']),
  },

  actions: {
    async fetchDriver({ commit }, { id, companyId, partnerId }: ActionGetDriverParams) {
      commit('isRequestInProgress', true);
      try {
        const result = await getDriver({ id, companyId, partnerId });
        commit('driver', result.driver);
        commit('users/userAccess', result.userAccess, { root: true });
      } catch (error) {
        commit('errorMessage', error);
        throw error;
      } finally {
        commit('isRequestInProgress', false);
      }
    },

    async updateDriver({ commit }, { id, companyId, partnerId, driver }: ActionSaveDriverParams) {
      commit('isRequestInProgress', true);
      try {
        await saveDriver({ id, companyId, partnerId, driver });
      } catch (error) {
        commit('errorMessage', error);
      } finally {
        commit('isRequestInProgress', false);
      }
    },

    async changeDriverLockState(
      { dispatch, state },
      {
        id,
        companyId,
        partnerId,
        resetLimits = false,
        limits: selectedLimits,
        onSuccess,
      }: ActionChangeDriverLockStateParams,
    ) {
      const driver = state.driver as UpdatedDriver;
      let limits = driver.limits as DriverLimits;
      if (resetLimits) {
        limits.day = null;
        limits.week = null;
        limits.month = null;
        limits.fuels = [];
      }
      if (selectedLimits) {
        limits = {
          ...driver.limits,
          ...selectedLimits,
        };

        if (limits.balance !== undefined) {
          delete limits.balance;
        }
      }

      await dispatch('updateDriver', {
        id,
        companyId,
        partnerId,
        driver: {
          name: driver.name,
          phone: driver.phone,
          limits: {
            ...limits,
            blocked: !limits?.blocked,
          },
        },
      });
      if (onSuccess) {
        onSuccess();
      }
    },

    async removeDriver({ commit }, { companyId, driverId }: { companyId: string; driverId: string }) {
      commit('isRequestInProgress', true);
      try {
        await removeDriver({ companyId, driverId });
      } catch (error) {
        commit('errorMessage', error);
      } finally {
        commit('isRequestInProgress', false);
      }
    },
  },
} as Module<State, unknown>;

type State = {
  driver: Driver;
  isRequestInProgress: boolean;
  errorMessage: string;
};

type ActionGetDriverParams = {
  id: string;
  partnerId: string;
  companyId: string;
};

type ActionSaveDriverParams = {
  id: string;
  partnerId: string;
  driver: UpdatedDriver;
  companyId: string;
};

type ActionChangeDriverLockStateParams = {
  id: string;
  partnerId: string;
  driver: Driver;
  limits?: DriverLimits;
  companyId: string;
  resetLimits?: boolean;
  onSuccess?: () => void;
};
