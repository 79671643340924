import { ActionContext } from 'vuex';
import { AuthState, RootState, api, AuthResponse } from '@/store';

type AuthParams = {
  token: string;
  pin: string;
};

export default async ({ commit }: ActionContext<AuthState, RootState>, { token, pin }: AuthParams) =>
  api
    .post({
      url: '/pinSubmit',
      data: { token, pin },
    })
    .then((res: AuthResponse) => {
      if (res.token !== undefined) {
        commit('setToken', res.token);
        return {};
      }
      return res;
    });
