import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fd38d81"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VCard = _resolveComponent("VCard")!
  const _component_UiModal = _resolveComponent("UiModal")!

  return (_openBlock(), _createBlock(_component_UiModal, {
    width: "1024px",
    maskClosable: "",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VCard, { class: "document rounded-4" }, {
        default: _withCtx(() => [
          _createElementVNode("iframe", {
            src: `https://res.fuelup.ru/docs/pages/${_ctx.docUrl}.html`,
            class: "document-view"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}