import { createStore, Store } from 'vuex';
import auth, { AuthState } from './auth';
import cabinet, { CabinetState } from './cabinet';
import orders, { OrdersState } from './orders';
import users, { UsersState } from './users';
import stations, { StationsState } from './stations';

export { default as api } from './api';
export * from './auth';
export * from './cabinet';
export * from './orders';
export * from './users';
import ReportsStore from './ReportsStore';
import DriversStore from './DriversStore';

export interface RootState {
  auth: AuthState;
  cabinet: CabinetState;
  orders: OrdersState;
  users: UsersState;
  stations: StationsState;
}

const store: Store<RootState> = createStore({
  modules: {
    auth,
    cabinet,
    orders,
    users,
    stations,
    reports: ReportsStore,
    drivers: DriversStore,
  },
});

export default store;
