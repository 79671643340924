
import { defineComponent, ref, watch, PropType, onMounted, onUnmounted } from 'vue';
import IMask from 'imask/esm/imask';
import AInput from 'ant-design-vue/es/input';
import UiText from './UiText.vue';
import type { SizeType } from 'ant-design-vue/es/config-provider/index';
import 'imask/esm/masked/number';

export default defineComponent({
  components: {
    AInput,
    UiText,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: 255,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Маска (imask)
     * https://imask.js.org/guide.html
     */
    mask: {
      type: Object as PropType<IMask.AnyMaskedOptions>,
      default: () => null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<SizeType>,
      default: 'default',
    },
  },
  setup(props, { emit }) {
    let maskInstance: IMask.InputMask<IMask.AnyMaskedOptions>;
    const localInputValue = ref(props.modelValue);

    watch(
      () => props.mask,
      () => {
        maskInstance.updateOptions(props.mask);
      },
    );

    watch(
      () => props.modelValue,
      () => {
        maskInstance.value = props.modelValue;
        localInputValue.value = maskInstance.value;
      },
    );

    onMounted(() => {
      maskInstance = IMask(inputRef.value.input.input, props.mask).on('accept', () => {
        emit('update:unmaskedValue', maskInstance.unmaskedValue);
        emit('update:modelValue', maskInstance.typedValue);
        localInputValue.value = maskInstance.value;
      });

      maskInstance.value = props.modelValue;
      localInputValue.value = maskInstance.value;
    });

    onUnmounted(() => {
      maskInstance.destroy();
    });

    const inputRef = ref();
    const focus = () => inputRef.value.input.focus();

    return {
      localInputValue,
      inputRef,
      focus,
    };
  },
});
