
import { defineComponent, ref, onMounted, watch, PropType } from 'vue';
import IMask from 'imask/esm/imask';
import AAutoComplete from 'ant-design-vue/es/auto-complete';
import { EventHandler } from 'ant-design-vue/es/_util/EventInterface';
import { DefaultOptionType } from 'ant-design-vue/es/select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let maskInstance: IMask.Masked<any> | null = null;

export default defineComponent({
  components: {
    AAutoComplete,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Маска (imask)
     * https://imask.js.org/guide.html
     */
    mask: {
      type: Object as PropType<IMask.AnyMaskedOptions>,
      default: () => null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<DefaultOptionType[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const inputLocalValue = ref<string | number>(props.modelValue);

    function initMask() {
      maskInstance = IMask.createMask(props.mask);
      updateMaskValue(props.modelValue?.toString() || '');
    }

    function updateMaskValue(value: string) {
      if (!maskInstance) {
        return;
      }
      maskInstance.resolve(value);
      inputLocalValue.value = maskInstance.value;
    }

    onMounted(() => {
      if (props.mask) {
        initMask();
      }
    });

    watch(
      () => props.mask,
      (newMask) => {
        if (newMask) {
          initMask();
        }
      },
    );

    watch(
      () => props.modelValue,
      (newValue) => {
        if (inputLocalValue.value === newValue) {
          return;
        }
        if (props.mask) {
          updateMaskValue(props.modelValue?.toString() || '');
          return;
        }
        inputLocalValue.value = newValue;
      },
    );

    const handleInput: EventHandler = (event: { target?: { value: string } }) => {
      let value = event.target?.value || '';
      if (props.mask && maskInstance) {
        updateMaskValue(value);
        emit('update:modelValue', maskInstance.value);
        return;
      }
      inputLocalValue.value = value;
      emit('update:modelValue', value);
    };

    return {
      inputLocalValue,
      handleInput,

      select: (event: Event) => {
        emit('select', event);
        return undefined;
      },
    };
  },
});
