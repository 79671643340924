import loadScript from '@/plugins/load-script';

export type GoogleMap = {
  getProjection: () => {
    fromLatLngToPoint: (point: number[]) => { x: number; y: number };
    fromPointToLatLng: (point: number[]) => { x: number; y: number };
  };
  getZoom: () => number;
  setCenter: (position: number[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maps: any;
};

export type GoogleMapMarker = {
  setVisible: (value: boolean) => void;
  setMap: (value: unknown) => void;
};

export type GoogleMapInfoWindow = {
  setContent: (element: unknown) => void;
  open: (map: unknown, marker?: unknown) => void;
  setPosition: (position: unknown) => void;
};

declare const google: GoogleMap;

const googleMapApiSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_KEY}`;
const defaultMapSettings = {
  zoom: 8,
  gestureHandling: 'greedy',
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: false,
  streetViewControl: true,
  rotateControl: false,
};

export const loadMap = async (
  mapElement: HTMLElement,
  infoElement: HTMLElement,
  center?: number[] | null,
  settings = defaultMapSettings,
) => {
  await loadScript({
    id: 'google-map',
    src: googleMapApiSrc,
  });

  const map = new google.maps.Map(mapElement, {
    ...settings,
    center: center ? new google.maps.LatLng(center[0], center[1]) : new google.maps.LatLng(55.75222, 37.61556),
  });

  const infoWindow = new google.maps.InfoWindow();
  infoWindow.setContent(infoElement);

  return [map, infoWindow];
};

export const showOnlineStations = (
  map: GoogleMap,
  stations: { id: string; type?: string; point?: number[] | null }[],
  onMarkerClick: (marker: GoogleMapMarker, stationId: string) => void,
) => {
  const markers: GoogleMapMarker[] = [];

  stations.forEach((station) => {
    if (!station.point) {
      return;
    }

    const marker = new google.maps.Marker({
      map,
      position: new google.maps.LatLng(station.point[0], station.point[1]),
      icon: {
        url: 'https://res.fuelup.ru/imgs/station4.png',
        scaledSize: new google.maps.Size(14, 14),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0),
      },
    });

    markers.push(marker);

    marker.addListener('click', () => {
      onMarkerClick(marker, station.id);
    });
  });

  return markers;
};

export const showOfflineStations = (
  map: GoogleMap,
  onMarkerClick: (marker: GoogleMapMarker, stationName: string, stationAddress: string) => void,
) => {
  const markers: GoogleMapMarker[] = [];

  offlineStations.forEach((station) => {
    if (!station.point) {
      return;
    }

    const marker = new google.maps.Marker({
      map,
      position: new google.maps.LatLng(station.point[0], station.point[1]),
      icon: {
        url: 'https://res.fuelup.ru/imgs/station5.png',
        scaledSize: new google.maps.Size(14, 14),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0),
      },
    });

    markers.push(marker);

    marker.addListener('click', () => {
      onMarkerClick(marker, station.name, station.address);
    });
  });

  return markers;
};

export function addMarker(map: GoogleMap, point: number[], url: string) {
  return new google.maps.Marker({
    map,
    position: new google.maps.LatLng(point[0], point[1]),
    icon: {
      scaledSize: new google.maps.Size(14, 14),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 0),
      url,
    },
  });
}

const offlineStations = [
  {
    id: 'a2cbde41-a771-4956-b374-a390c77c4c26',
    name: 'АЗС №3 Самохина',
    point: [55.161754, 61.522569],
    address: 'г. Челябинск, Самохина 184/1',
  },
  {
    id: 'e5198ff3-f362-4e99-8ec2-74ab716017ac',
    name: 'АЗС № 38 Челябинск',
    point: [55.188744, 61.355295],
    address: 'г. Челябинск, ул. Проспект Победы 290/1	',
  },
  {
    id: 'a76b410e-3b16-441b-afcc-333e73a07c1c',
    name: 'АЗС № 11 Миасс',
    point: [55.0970932, 60.1310372],
    address: 'г. Миасс, Тургоякское шоссе, д. 9/19д',
  },
  {
    id: '812fe4c9-986f-4708-969c-97da21d8c6b4',
    name: 'АЗС № 17 Пермь',
    point: [58.112244, 56.361601],
    address: 'г. Пермь, ул. Соликамская 313а/7',
  },
  {
    id: 'a578ab62-3b2f-433b-864b-7746f906d1c0',
    name: 'АЗС № 14 Кыштым',
    point: [55.700344, 60.5595513],
    address: 'г. Кыштым, ул. Челюскинцев, 74',
  },
  {
    id: 'cd3796a6-1cfb-47ca-9e6c-73b800b7ee7e',
    name: 'АЗС № 8 Валдайская',
    point: [55.19337, 61.46319],
    address: 'г. Челябинск, ул. Валдайская, д. 27/1',
  },
  {
    id: '22a0671d-dc87-4642-94d3-6aa58ee1680c',
    name: 'АЗС № 5 Степана',
    point: [55.130125, 61.402816],
    address: 'г. Челябинск, ул. Забалотная, д. 17/4',
  },
  {
    id: '2e334cac-7724-4ea2-8617-2e0ff1d0c00c',
    name: 'АЗС № 18 ЧМЗ',
    point: [55.25467, 61.42001],
    address: 'г. Челябинск, ул. Монтажников, д. 13/1',
  },
  {
    id: '61b458fc-f607-446d-a219-a5d9d75d20c0',
    name: 'АЗС № 19 Копейск',
    point: [55.121374, 61.618713],
    address: 'г. Копейск, ул. Элеваторная, 11, ст.1',
  },
  {
    id: 'a9372fa6-f266-4764-974a-0dfae7664a74',
    name: 'АЗС № 20 Челябинск',
    point: [55.142979, 61.424792],
    address: 'г. Челябинск, ул. Шота Руставели, 32/1',
  },
  {
    id: '226c7272-e50d-4f02-b1a6-ee88f2a0403e',
    name: 'АЗС № 26 Коркино',
    point: [54.894187, 61.425595],
    address: 'г. Коркино, ул. Заотвальная, 1а',
  },
  {
    id: 'ae118e50-d8e5-40c0-9a61-928d40777196',
    name: 'АЗС № 37 Челябинск',
    point: [55.157535, 61.297407],
    address: 'г. Челябинск, ул. Северная 40/1',
  },
  {
    id: 'd0c5e05c-7f75-4570-ad1d-7583c98f50bb',
    name: 'АЗС № 39 Копейск',
    point: [55.228794, 61.721017],
    address: 'г. Копейск, п. Вахрушева, ул. 22-го Партсъезда, 1а/3',
  },
  {
    id: '54b8f41d-d257-4874-a48b-1fd2b8fc4a57',
    name: 'АЗС № 40 Челябинск',
    point: [55.164042, 61.466348],
    address: 'г. Челябинск, пр. Ленина, д. 2б',
  },
  {
    id: '0c63d1fb-e02b-42f1-b37c-879689989be3',
    name: 'Флагман 6',
    point: [50.552413, 137.027339],
    address: 'Хабаровский край, Комсомольск-на-Амуре, ул. Кирова, 48/1',
  },
  {
    id: '63b24e6f-9344-4c71-8ecf-0863e640915d',
    name: 'АЗС 11',
    point: [53.210047, 50.212025],
    address: 'г. Самара, ул. Высоковольтная 44а',
  },
  {
    id: '114c0f87-b6fd-4d0d-a726-a40914eb3bf3',
    name: 'Роза Мира №13',
    point: [53.291865, 50.274907],
    address: 'Самарская область, Волжский район,9 КМ+600 м Подъезда к г. Самара с правой стороны ТЦ МЕГА-ИКЕА',
  },
  {
    id: '207058b5-dd95-47f7-bb0c-200132696b5e',
    name: 'Роза Мира №14',
    point: [53.334952074, 50.300875869],
    address: 'Самара, Московское шоссе, 24-й км, 7А',
  },
  {
    id: 'eeeff5f3-61d4-47f5-979a-7587a29457bf',
    name: 'Роза Мира №10',
    point: [53.2210946, 50.2043871],
    address: 'г. Самара , ул. Советской Армии 169а.',
  },
  {
    id: '531b5325-7f18-4d20-bef7-8c4b9ea1e6ee',
    name: 'АЗС №30',
    point: [53.441845, 58.999108],
    address: 'г. Магнитогорск, ул. Северный переход, 16В',
  },
  {
    id: '4d9d0c5c-280e-4e4e-8781-75fccdbf1009',
    name: 'АЗС №29',
    point: [53.357787, 58.936112],
    address: 'г. Магнитогорск, ул. Шоссе западное 480 В',
  },
];
