import { ActionContext } from 'vuex';
import { AuthState, RootState, api } from '@/store';
import recaptcha from '@/plugins/recaptcha';

type PinRequestParams = {
  phone: string;
  strict?: boolean;
  utm?: string;
  // b2b?: boolean;
};

/**
 * Запрос PIN-кода
 */
export default async (_: ActionContext<AuthState, RootState>, { phone, strict, utm }: PinRequestParams) =>
  api.post({
    url: '/pinRequest',
    data: {
      phone,
      strict,
      utm,
      // b2b,
      recaptcha: await recaptcha('pinRequest'),
    },
  });
