import { api } from '@/store';
import { q } from '@/store/api';
import { getAuthToken } from '@/store/auth';
import useQuery from '@/helpers/useQuery';
import { graphql } from '@/gql';
import { GetMonthlyReportLinkQueryVariables } from '@/gql/graphql';

export function getReport({ companyId, month }: { companyId: string; month: string }): Promise<ReportItem[]> {
  return api
    .graphql({
      query: q`
      query getReport ($companyId: ID!, $month: ID!) {
        cabinet {
          company(id: $companyId) {
            report(month: $month) {
              day credit debit returns balance
            }
          }
        }
      }
    `,
      variables: { companyId, month },
      token: getAuthToken(),
    })
    .then((result: GetReportResult) => result.cabinet?.company?.report || []);
}

export const GET_BALANCE_REPORT_URL_QUERY = q`
  query getBalanceReportUrl ($companyId: ID, $partnerId: ID, $contractId: ID, $month: String!) {
    cabinet {
      company(id: $companyId, partnerId: $partnerId, contractId: $contractId) {
        getBalanceReportLink(month: $month)
      }
    }
  }
`;

export function getBalanceReportUrl({
  companyId,
  departmentId,
  contractId,
  month,
}: {
  companyId?: string;
  departmentId?: string;
  contractId?: string;
  month: string;
}) {
  return api
    .graphql({
      query: GET_BALANCE_REPORT_URL_QUERY,
      variables: {
        companyId,
        partnerId: departmentId,
        contractId,
        month,
      },
      token: getAuthToken(),
    })
    .then((result: getBalanceReportUrlResult) => result.cabinet.company.getBalanceReportLink || '');
}

type getBalanceReportUrlResult = {
  cabinet: {
    company: {
      getBalanceReportLink: string;
    };
  };
};

export function getReports({
  companyId,
  contractId,
  departmentId,
}: {
  companyId?: string;
  contractId?: string;
  departmentId?: string;
}): Promise<ReportsItem[]> {
  return api
    .graphql({
      query: q`
      query getReports ($companyId: ID, $partnerId: ID, $contractId: ID) {
        cabinet {
          company(id: $companyId, partnerId: $partnerId, contractId: $contractId) {
            reports {
              month year credit creditCount debit debitCount returns cardCount
            }
          }
        }
      }
    `,
      variables: {
        companyId,
        contractId,
        partnerId: departmentId,
      },
      token: getAuthToken(),
    })
    .then((result) => result.cabinet?.company?.reports || []);
}

type GetReportResult = {
  cabinet: {
    company: {
      report: ReportItem[];
    };
  };
};

export type ReportItem = {
  balance: number;
  credit: number;
  day: number;
  debit: number;
  returns: number;
};

export type ReportsItem = {
  cardCount: number | null;
  credit: number;
  creditCount: number;
  debit: number;
  debitCount: number;
  month: number;
  returns: number;
  year: number;
};

export async function getMonthlyReportLink(v: GetMonthlyReportLinkQueryVariables) {
  const q = graphql(`
    query getMonthlyReportLink($id: ID!, $month: String!) {
      cabinet {
        company(id: $id) {
          monthlyReportLink(month: $month)
        }
      }
    }
  `);

  return await useQuery(q, v);
}
