import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ATextArea = _resolveComponent("ATextArea")!

  return (_openBlock(), _createBlock(_component_ATextArea, {
    rows: _ctx.rows,
    value: _ctx.value,
    disabled: _ctx.disabled,
    placeholder: _ctx.placeholder,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event)))
  }, null, 8, ["rows", "value", "disabled", "placeholder"]))
}