
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import UiButton from '@/components/UiButton.vue';

export default defineComponent({
  name: 'PinRepeat',
  props: {
    lock: Number,
  },
  components: { UiButton },
  setup(props, { emit }) {
    const interval = ref<ReturnType<typeof setTimeout> | number>(0);
    const left = ref(0);

    const updateLeft = () => {
      left.value = (props.lock ?? 0) - Math.floor(+new Date() / 1000);
    };

    const triggerRepeat = () => {
      emit('submit');
    };

    onMounted(() => {
      updateLeft();
      interval.value = setInterval(() => {
        updateLeft();
      }, 1000);
    });

    onUnmounted(() => {
      clearInterval(interval.value);
    });

    return {
      interval,
      left,
      triggerRepeat,
    };
  },
});
