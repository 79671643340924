
import { defineComponent, PropType } from 'vue';
import ABreadcrumb from 'ant-design-vue/es/breadcrumb';
import { LocationQuery } from 'vue-router';

export default defineComponent({
  components: {
    ABreadcrumb,
    ABreadcrumbItem: ABreadcrumb.Item,
  },
  props: {
    /**
     * Элементы хлебных крошек
     */
    items: {
      type: Array as PropType<BreadcrumbItem[]>,
      default: () => [],
    },
  },
});

export type BreadcrumbItem = {
  title: string;
  urlPath?: string;
  query?: LocationQuery;
};
