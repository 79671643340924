import { defineComponent, h } from 'vue';
import './VPhone.scss';

export const VPhone = defineComponent({
  name: 'VPhone',
  props: {
    value: [String],
  },
  render() {
    const matches = String(this.value || '').match(/^7?(9\d{2})(\d{3})(\d{2})(\d{2})$/);
    return this.value
      ? h(
          'span',
          { class: `v-phone${matches ? '' : ' v-phone--error'}` },
          matches ? `+7 (${matches[1]}) ${matches[2]}-${matches[3]}-${matches[4]}` : this.value,
        )
      : null;
  },
});
