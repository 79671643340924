import { defineComponent, h } from 'vue';
import { VBtn } from '@/components/Button';
import { VSvgIcon } from '@/components/Images';
import './VPagination.scss';

export default defineComponent({
  name: 'VPagination',
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    size: {
      type: [String, Number],
      default: 20,
    },
    total: Number,
  },
  computed: {
    pages(): number {
      return Math.max(1, Math.ceil(Number(this.total) / Math.max(1, Number(this.size))));
    },
    currentStart(): number {
      return Math.max(1, Math.min(this.modelValue, this.pages - 2) - 2);
    },
    currentEnd(): number {
      return Math.min(this.pages, Math.max(5, this.modelValue + 2));
    },
    prevItems(): number {
      if (this.currentStart > 1) {
        return this.currentStart === 3 ? 2 : 1;
      }
      return 0;
    },
    lastItems(): number {
      const lastItems = this.pages - this.currentEnd;
      if (lastItems > 0) {
        return lastItems === 2 ? 2 : 1;
      }
      return 0;
    },
  },
  render() {
    const { prevItems, lastItems, currentStart, currentEnd, pages } = this;
    return h(
      'div',
      { class: 'v-pagination d-flex flex-center' },
      [
        ...new Array(prevItems).fill(0).map((_, index) => 1 + index),
        ...(prevItems < currentStart - 1 ? ['PREV'] : []),
        ...new Array(currentEnd - currentStart + 1).fill(0).map((_, index) => currentStart + index),
        ...(pages - lastItems > currentEnd + 1 ? ['NEXT'] : []),
        ...new Array(lastItems)
          .fill(0)
          .map((_, index) => pages - index)
          .reverse(),
      ].map((page) => {
        const isNav = ['PREV', 'NEXT'].includes(page.toString());
        return h(
          VBtn,
          {
            color: page === this.modelValue ? 'primary' : undefined,
            tile: true,
            outlined: !isNav,
            onClick: () => this.goto(page),
          },
          () => (isNav ? h(VSvgIcon, { name: 'arrows/dots' }) : page),
        );
      }),
    );
  },
  methods: {
    goto(page: string | number) {
      switch (page) {
        case 'PREV':
          break;
        case 'NEXT':
          break;
        default:
          this.$emit('update:modelValue', page);
          break;
      }
    },
  },
});
