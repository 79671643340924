import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ADatePicker = _resolveComponent("ADatePicker")!

  return (_openBlock(), _createBlock(_component_ADatePicker, {
    class: "ui-date-picker",
    value: _ctx.defaultValue,
    modelValue: _ctx.modelValue,
    locale: _ctx.localeRu,
    disabled: _ctx.disabled,
    showTime: _ctx.showTime,
    allowClear: _ctx.allowClear,
    format: _ctx.format,
    placeholder: _ctx.placeholder,
    onChange: _ctx.update
  }, null, 8, ["value", "modelValue", "locale", "disabled", "showTime", "allowClear", "format", "placeholder", "onChange"]))
}