
import { defineComponent, h, VNode, ref, watch } from 'vue';
import { VRow, VCol } from '@/components/Layout';
import UiInput from '@/components/UiInput.vue';

export default defineComponent({
  name: 'PinCode',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const pin = ref([
      props.modelValue?.[0] ?? '',
      props.modelValue?.[1] ?? '',
      props.modelValue?.[2] ?? '',
      props.modelValue?.[3] ?? '',
    ]);

    watch(
      () => props.modelValue,
      (value) => {
        pin.value.forEach((_, index) => {
          pin.value[index] = value[index] ?? '';
        });
      },
    );
    const getPinRefName = (index: number) => `pin_${index}`;

    return {
      pin,
      getPinRefName,

      updateValue(index: number, value: string | null, $refs: { [key: string]: unknown }) {
        // если ввели значение
        if (value) {
          pin.value[index] = value;
          const nextRef = $refs[getPinRefName(index + 1)] as HTMLInputElement;
          if (nextRef) {
            nextRef.focus();
          }
        }
        // если стёрли значение
        else {
          pin.value[index] = '';
        }

        emit('update:modelValue', pin.value.join(''));
      },
    };
  },

  mounted() {
    setTimeout(() => {
      const inputRef = this.$refs[this.getPinRefName(0)] as HTMLInputElement;
      inputRef && inputRef?.focus();
    }, 240);
  },

  render(): VNode {
    return h(
      VRow,
      {
        class: 'pin-code',
      },
      () => {
        return this.pin.map((_, index) =>
          h(VCol, { cols: 3 }, () => {
            return h(UiInput, {
              type: 'text',
              size: 'large',
              ref: this.getPinRefName(index),
              mask: { mask: '0' },
              disabled: this.disabled,
              modelValue: this.pin[index],
              'onUpdate:modelValue': (value: string) => this.updateValue(index, value, this.$refs),
            });
          }),
        );
      },
    );
  },
});
