
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    docUrl: {
      type: String,
      required: true,
    },
  },
});
