import { ActionContext } from 'vuex';
import { CabinetState, RootState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  query getReport ($companyId: ID!, $month: ID!) {
    cabinet {
      company(id: $companyId) {
        report(month: $month) {
          day credit debit returns balance
        }
      }
    }
  }
`;

export default (
  {
    rootState: {
      cabinet: { company },
      auth: { token },
    },
  }: ActionContext<CabinetState, RootState>,
  month: string,
) =>
  api
    .graphql({
      query,
      variables: {
        companyId: company?.id,
        month,
      },
      token,
    })
    .then((result) => result.cabinet?.company?.report);
