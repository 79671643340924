
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  props: {
    selectedItemId: {
      type: String,
      default: '',
    },
    isSelectedCompanyNumberVisible: {
      type: Boolean,
      default: true,
    },
    expandToTop: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['select'],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const getCompanyRouteTypeById = computed(() => store.getters['cabinet/companyRouteTypeById']);

    return {
      companySelectOptions: computed(() => store.getters['cabinet/companySelectOptions']),
      selectedCompanyName: computed(() => store.getters['cabinet/company']?.name),
      selectedCompanyNumber: computed(() => {
        const company = store.getters['cabinet/company'];
        const number: number = company?.contract.number;
        if (!number) {
          return '';
        }
        const companyType = company?.type || '';
        if (companyType === 'azs') {
          const contractTypeCode: string = company?.contract.type;
          const type =
            {
              online: 'Онлайн договор',
              custom: 'Онлайн договор',
              offline: 'Оффлайн договор',
            }[contractTypeCode] || 'Договор';
          return number ? `${type}: №${number}` : '';
        }
        return `Договор: №${number}`;
      }),
      selectedCompany: computed(() => [props.selectedItemId]),

      selectCompany(selectedMenuItem: { key: string }) {
        const companyId = selectedMenuItem.key || '';
        emit('select', companyId);

        router.push({
          ...route,
          params: {
            ...route.params,
            id: companyId,
            type: getCompanyRouteTypeById.value(companyId),
          },
          query: undefined,
        });
      },
    };
  },
});
