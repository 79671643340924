
import { defineComponent, computed } from 'vue';
import { CloseOutlined } from '@ant-design/icons-vue';
import FuelupLogo from '@/views/components/FuelupLogo.vue';
import useCabinetStore from '@/store/CabinetStore';
import useCabinetMenuStore from '@/store/CabinetMenuStore';
// import SideBanner from '@/views/components/SideBanner/SideBanner.vue';

export default defineComponent({
  components: {
    FuelupLogo,
    // SideBanner,
    IconCloseOutlined: CloseOutlined,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    isButtonCloseVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { company, companyType } = useCabinetStore();
    const { menuItems } = useCabinetMenuStore({
      id: computed(() => props.id),
      type: computed(() => props.type),
    });
    const close = () => emit('close');

    return {
      company,
      companyType,
      menuItems,
      close,
      handleMenuItemClick: (items: unknown[] = []) => !items.length && close(),
    };
  },
});
