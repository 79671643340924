import { ActionContext } from 'vuex';
import { CabinetState, RootState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  query getCompanyUsers ($companyId: ID) {
    cabinet {
      company(id: $companyId) {
        users {
          count
          items {
            phone
            ... on LKUser { email role }
          }
        }      
      }
    }    
  }
`;

export default (
  {
    rootState: {
      auth: { token },
    },
  }: ActionContext<CabinetState, RootState>,
  companyId: string,
) =>
  api
    .graphql({
      query,
      variables: { companyId },
      token,
    })
    .then(({ cabinet }) => cabinet?.company?.users || []);
