import { api } from '@/store';
import { q } from '@/store/api';
import { getAuthToken } from '@/store/auth';

export function getPayments({
  companyId,
  departmentId,
  page,
  pageSize,
}: {
  companyId: string;
  departmentId?: string;
  page: number;
  pageSize: number;
}): Promise<Payments> {
  return api
    .graphql({
      query: q`
        query getPayments ($companyId: ID!, $partnerId: ID, $filter: PaymentsQueryParams) {
          cabinet {
            company(id: $companyId, partnerId: $partnerId) {
              payments {
                balance
                needMoney
                count
                cards { id name provider { id name } }
                items(filter: $filter) {
                  id amount type status statusDescription transactionId provider { name } createdAt
                  balance { amount description }
                  ... on OnlinePayment { pan }
                }
              }
            }
          }
        }
      `,
      variables: {
        companyId,
        partnerId: departmentId,
        filter: { page: page, size: pageSize },
      },
      token: getAuthToken(),
    })
    .then((result) => result.cabinet?.company?.payments || {});
}

export const GET_PAYMENTS_PROVIDERS_QUERY = q`
  query getPaymentProviders ($companyId: ID) {
    paymentProviders (companyId: $companyId) {
      id
      name
      service
      type
    }
  }
`;

export function getPaymentProviders({ companyId }: { companyId?: string }): Promise<PaymentProvider[]> {
  return api
    .graphql({
      query: GET_PAYMENTS_PROVIDERS_QUERY,
      variables: {
        companyId,
      },
      token: getAuthToken(),
    })
    .then((result) => result?.paymentProviders ?? []);
}

export type Payments = {
  count: number;
  items: PaymentsItem[];
  cards: PaymentCard[];
  needMoney: boolean;
  balance: number;
};

type PaymentsItem = {
  id: string;
  amount: number;
  type: string;
  status: string;
  statusDescription: string | null;
  transactionId: string;
  provider: {
    name: string;
  };
  createdAt: string;
  balance: [
    {
      amount: number;
      description: string;
    },
  ];
  pan: string;
};

export type PaymentCard = {
  id: string;
  name: string;
  provider: {
    id: string;
    name: string;
  };
};

export type PaymentProvider = {
  id: string;
  name?: string;
  service?: PaymentProviderService;
  type?: PaymentProviderType;
};

export type PaymentProviderService = 'SBERBANK' | 'INVOICE';
export type PaymentProviderType = 'CARD' | 'INVOICE';

export const PAYMENT_PROVIDER_TYPES = {
  card: 'CARD',
  invoice: 'INVOICE',
};
