import { Analytics, AnalyticsOptions } from './Analytics';
import type { App, Plugin } from 'vue';

export * from './Analytics';

export type AnalyticsPlugin = Plugin;

let analytics: Analytics;

export const initAnalytics = (options: AnalyticsOptions): AnalyticsPlugin => {
  return {
    install(app: App): void {
      analytics = new Analytics(options);
      app.config.globalProperties.$analytics = analytics;
    },
  };
};

export const useAnalytics = (): Analytics => analytics;

export const useAnalyticsMock = () => {
  const handle = () => void 0;

  return { handle };
};
