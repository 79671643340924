import { ActionContext } from 'vuex';
import { AuthState, RootState, api } from '@/store';

type AuthParams = {
  token: string;
  password: string;
};

export default async (_: ActionContext<AuthState, RootState>, params: AuthParams) => {
  await api.post({ url: '/restoreSubmit', data: params });
};
