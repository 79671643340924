import dayjs from 'dayjs';
import { createApp } from 'vue';
import 'dayjs/locale/ru';
import { initAnalytics } from '@/plugins/analytics';
import components from '@/components';
import '@/styles';
import App from './App.vue';
import router from './router';
import store from './store';

dayjs.locale('ru');

const app = createApp(App).use(components).use(store).use(router);

app.use(
  initAnalytics({
    router,
    yandexCounters: [92266985, 92706008],
    dpmId: 'bcf5778a-7a81-4a19-adce-b27a5ed4c8df',
    debugEnabled: true,
    skipTracking: process.env.NODE_ENV === 'development',
  }),
);

store?.commit('auth/load');

router?.isReady().then(() => {
  app.mount('body');
});

export default app;
