import { defineComponent, h } from 'vue';
import './VRadio.scss';

export default defineComponent({
  name: 'VRadio',
  props: {
    modelValue: [String, Number, Boolean],
    value: [String, Number, Boolean],
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  render() {
    return h('label', { class: 'v-radio' }, [
      h('input', {
        type: 'radio',
        value: this.value,
        checked: this.modelValue === this.value,
        disabled: this.disabled,
        onChange: () => {
          this.$emit('update:modelValue', this.value);
        },
      }),
      h('i'),
      h('span', this.label),
    ]);
  },
});
