
import { defineComponent } from 'vue';
import ARadioGroup from 'ant-design-vue/es/radio/Group';
import { RadioChangeEvent } from 'ant-design-vue/es/radio/interface';

export default defineComponent({
  components: {
    ARadioGroup,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  setup(props, { emit }) {
    return {
      update: (event: RadioChangeEvent) => {
        emit('update:modelValue', event.target?.value);
      },
    };
  },
});
