
import { defineComponent } from 'vue';
import ADropdown from 'ant-design-vue/es/dropdown';
import AMenu from 'ant-design-vue/es/menu';

export default defineComponent({
  components: {
    ADropdown,
    AMenu,
  },
});
